import React from "react";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  ThemeProvider,
  createTheme
} from "@mui/material";
import { Email as EmailIcon } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2" // A nice blue color for a travel theme
    },
    secondary: {
      main: "#ff9800" // An orange accent color
    }
  }
});

const EmailValidationPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "#f5f5f5"
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 400
          }}
        >
          <EmailIcon sx={{ fontSize: 64, color: "primary.main", mb: 2 }} />
          <Typography variant="h4" component="h1" gutterBottom>
            Validate Your Email
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            We&apos;ve sent a validation link to your email address. Please
            check your inbox and click the link to complete your registration
            with Trotter.
          </Typography>
          <CircularProgress size={30} thickness={5} sx={{ mb: 2 }} />
          <Typography variant="body2" color="text.secondary" align="center">
            Didn&apos;t receive the email? Check your spam folder or contact our
            support team.
          </Typography>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default EmailValidationPage;

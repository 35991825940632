import { useWebClient } from "./useWebClient";
import { useTypedAsyncFn } from "./useTypedAsyncFn";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { useGetTrips } from "./useGetTrips";
import { useNavigate } from "react-router-dom";

export const useDuplicateTrip = () => {
  const webClient = useWebClient();
  const { t } = useTranslation();
  const [, getTrips] = useGetTrips();
  const navigate = useNavigate();

  return useTypedAsyncFn(async (tripId: string) => {
    const promise = webClient.post(`/trips/duplicate`, {
      tripId
    });
    toast.promise(promise, {
      success: t("duplicate.success"),
      error: t("duplicate.error"),
      loading: t("duplicate.loading")
    });
    try {
      const res = await promise;
      await getTrips();
      navigate(`/map?id=${res.data}`);
    } catch {
      return;
    }
  }, []);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WithHeader from "../../Layout/WithHeader";
import {
  Map,
  MapRef,
  ScaleControl,
  NavigationControl,
  Source,
  Layer
} from "react-map-gl";
import { MapLayerMouseEvent } from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { getUserToken } from "../../reducers/auth.reducers";

const TrendingPage: FC = () => {
  const [cursor, setCursor] = useState<string>("grab");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleMapClick = async (e: MapLayerMouseEvent) => {
    console.log(e);
    if (!e.features || !e.features[0]) return;
    if (e.features[0].layer.id === "city-layer" && e.features[0].properties) {
      console.log(e.features[0]);
      dispatch({
        type: "SEARCH",
        payload: {
          place: {
            cityName: e.features[0].properties.name,
            lat: parseFloat(
              e.features[0].properties.bbox
                .split(/[ [\],]+/)
                .filter((x: string) => x.trim() !== "")[1]
            ),
            lon: parseFloat(
              e.features[0].properties.bbox
                .split(/[ [\],]+/)
                .filter((x: string) => x.trim() !== "")[0]
            )
          }
        }
      });
      console.log({
        cityName: e.features[0].properties.name,
        lat:
          parseFloat(
            e.features[0].properties.bbox
              .split(/[ [\],]+/)
              .filter((x: string) => x.trim() !== "")[1]
          ) + 0.1,
        lon:
          parseFloat(
            e.features[0].properties.bbox
              .split(/[ [\],]+/)
              .filter((x: string) => x.trim() !== "")[0]
          ) + 0.1
      });
      navigate("/map");
    }
  };
  const mapRef = useRef<MapRef>(null);
  const { t } = useTranslation();
  const isLoggedIn = useSelector(getUserToken);

  return (
    <>
      <WithHeader isLoggedIn={isLoggedIn !== undefined} />
      <TrendingPageLayout>
        <TrendingCitiesNames>
          <h1 style={{ textDecoration: "underline" }}>
            {t("trendingPage.title")}
          </h1>
          <TopCityContainer>
            <WorkspacePremiumIcon
              fontSize="large"
              style={{ color: "#FFD700" }}
            />
            <h2>Seoul</h2>
          </TopCityContainer>
          <TopCityContainer>
            <WorkspacePremiumIcon
              fontSize="large"
              style={{ color: "#C0C0C0" }}
            />
            <h2>Paris</h2>
          </TopCityContainer>
          <TopCityContainer>
            <WorkspacePremiumIcon
              fontSize="large"
              style={{ color: "b87333" }}
            />
            <h2>Toronto</h2>
          </TopCityContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: "50px"
            }}
          >
            <InfoOutlinedIcon fontSize="large" />
            <p>{t("trendingPage.text")}</p>
          </div>
        </TrendingCitiesNames>
        <MapContainer>
          <Map
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{ zoom: 1 }}
            cursor={cursor}
            ref={mapRef}
            mapStyle="mapbox://styles/mapbox/streets-v12"
            projection={"globe"}
            onClick={handleMapClick}
            onMouseEnter={() => setCursor("pointer")}
            onMouseLeave={() => setCursor("grab")}
            interactiveLayerIds={["city-layer"]}
            minZoom={1}
          >
            <NavigationControl />
            <ScaleControl maxWidth={100} unit="metric" />
            <Source
              id="cities"
              type="geojson"
              data={`${process.env.REACT_APP_URI}/trending.geojson`}
            />
            <Layer
              id="city-layer"
              type="circle"
              source="cities"
              paint={{
                "circle-radius": 8,
                "circle-stroke-width": 1,
                "circle-color": "red",
                "circle-stroke-color": "white"
              }}
            />
          </Map>
        </MapContainer>
      </TrendingPageLayout>
    </>
  );
};

const MapContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

const TrendingPageLayout = styled.div`
  padding-top: 49px;
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  overflow: hidden;
`;

const TrendingCitiesNames = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 20%;
`;

const TopCityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1px;
  gap: 8px;
`;

export default TrendingPage;

export const COLORS = {
  white: "#FFFFFF",
  black: "#252525",
  grey: "#95B0B4",
  blueGreen: "#324B4E",
  red: "#DB0000",
  darkRed: "#8A0000",
  bg: "#F3F4F8",
  text: "#101223",
  links: "#008E8B",
  detail: "#AA7455",
  border: "#BBBBBB",
  light: "#F3F4F8",
  dark: "#0D0E1C",
  lightBlue: "#BBDEFA",
  blue: "#1464C0",
  lightGreen: "#7DB343",
  green: "#43A047",
  sky: "#DFF1FF"
};

export const FONT = "'Lato', sans-serif;";

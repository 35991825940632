import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { Table } from "antd";
import { COLORS, FONT } from "../../UI/Colors";
import styled from "styled-components";
import { Loader } from "../../components/LibUI";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { useSelector } from "react-redux";
import { getUser } from "../../reducers/auth.reducers";

type Event = {
  id: string;
  name: string;
  description: string;
  place: string;
  host: string;
  tags: string[];
  maxSub: number;
  participantList: string[];
  start: string;
  end: string;
  open: string;
  close: string;
};

const EventCreate: FC = () => {
  const [events, setEvents] = useState<Event[]>([]);

  const { t } = useTranslation();
  const originalUser = useSelector(getUser);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false // Use 24-hour format
  };

  const getEvents = async () => {
    try {
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_URI}/event`,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      });
      setEvents(response.data);
    } catch (e) {
      console.error("An error occured while loading the events: ", e);
      toast.error(t("event.loadError"));
    }
  };

  const deleteEvent = async (id: string) => {
    try {
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_SERVER_URI}/event/${id}`,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }
      });
      res.status === 204
        ? toast.success(t("event.deleteSuccess"))
        : toast.error(t("event.deleteError"));
      getEvents();
    } catch (e) {
      console.error("An error occured while deleting the event: ", e);
      toast.error(t("event.deleteError"));
    }
  };

  const subEvent = async (id: string, userId: string) => {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URI}/event/${id}/subscribe`,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        },
        data: {
          userId: userId
        }
      });
      res.status === 200
        ? toast.success(t("event.subSuccess"))
        : toast.error(t("event.subError"));
      getEvents();
    } catch (e) {
      console.error("An error occured while subscribing to the event: ", e);
      toast.error(t("event.subError"));
    }
  };

  const unsubEvent = async (id: string, userId: string) => {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URI}/event/${id}/unsubscribe`,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        },
        data: {
          userId: userId
        }
      });
      res.status === 200
        ? toast.success(t("event.unsubSuccess"))
        : toast.error(t("event.unsubError"));
      getEvents();
    } catch (e) {
      console.error("An error occured while unsubscribing from the event: ", e);
      toast.error(t("event.unsubError"));
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <TableContainer>
      {events.length === 0 && originalUser === undefined ? (
        <Loader />
      ) : (
        <Table
          dataSource={events.map((event, index) => ({
            ...event,
            key: index.toString()
          }))}
          columns={[
            {
              title: t("event.name"),
              dataIndex: "name",
              key: "name",
              sorter: (a, b) => a.name.localeCompare(b.name)
            },
            {
              title: t("event.description"),
              dataIndex: "description",
              key: "description"
            },
            {
              title: t("event.host"),
              dataIndex: "host",
              key: "host",
              sorter: (a, b) => a.host.localeCompare(b.host)
            },
            {
              title: t("event.openingDate"),
              dataIndex: "open",
              key: "open",
              sorter: (a, b) => (new Date(a.id) < new Date(b.id) ? -1 : 1),
              render: function (open, event) {
                return (
                  <p>
                    {" "}
                    {t("event.open")}:{" "}
                    {new Date(open).toLocaleDateString(undefined, dateOptions)}
                    <br /> {t("event.close")}:{" "}
                    {new Date(event.close).toLocaleDateString(
                      undefined,
                      dateOptions
                    )}
                  </p>
                );
              }
            },
            {
              title: t("event.startDate"),
              dataIndex: "start",
              key: "start",
              sorter: (a, b) => (new Date(a.id) < new Date(b.id) ? -1 : 1),
              render: function (start, event) {
                return (
                  <p>
                    {" "}
                    {t("event.start")}:{" "}
                    {new Date(start).toLocaleDateString(undefined, dateOptions)}
                    <br /> {t("event.end")}:{" "}
                    {new Date(event.end).toLocaleDateString(
                      undefined,
                      dateOptions
                    )}
                  </p>
                );
              }
            },
            {
              title: "",
              dataIndex: "participantList",
              key: "participantList",
              render: function (participantList: string[], event) {
                if (new Date(event.close) < new Date()) {
                  return new Date(event.end) < new Date()
                    ? t("event.ended")
                    : t("event.closed");
                }
                if (originalUser.username !== event.host) {
                  return !participantList ||
                    !participantList.includes(originalUser.username) ? (
                    <SubButton
                      onClick={() => subEvent(event.id, originalUser.username)}
                    >
                      {" "}
                      {t("event.sub")}{" "}
                    </SubButton>
                  ) : (
                    <UnSubButton
                      onClick={() =>
                        unsubEvent(event.id, originalUser.username)
                      }
                    >
                      {" "}
                      {t("event.unsub")}{" "}
                    </UnSubButton>
                  );
                } else {
                  return (
                    <div>
                      {participantList.length > 0
                        ? participantList.join(", ")
                        : t("event.noParticipant")}
                      <IconButton
                        onClick={() => deleteEvent(event.id)}
                        color="inherit"
                        aria-label="delete"
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  );
                }
              }
            }
          ]}
        />
      )}
    </TableContainer>
  );
};

const SubButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: ${COLORS.blue};
  border: none !important;
  border-radius: 10px;
  color: ${COLORS.white};
  box-shadow: 3px 2px 10px rgba(${COLORS.text}, 0.1);
  cursor: pointer;
  font-weight: bolder;
  font-size: 15px;
  font-family: ${FONT};

  &:hover {
    background-color: ${COLORS.blueGreen};
    transition-duration: 0.4s;
  }
`;

const UnSubButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: ${COLORS.red};
  border: none !important;
  border-radius: 10px;
  color: ${COLORS.white};
  box-shadow: 3px 2px 10px rgba(${COLORS.text}, 0.1);
  cursor: pointer;
  font-weight: bolder;
  font-size: 15px;
  font-family: ${FONT};

  &:hover {
    background-color: ${COLORS.darkRed};
    transition-duration: 0.4s;
  }
`;

const TableContainer = styled.div`
  margin-left: 50px;
  margin-right: 50px;
`;

export default EventCreate;

import React, { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  MenuItem
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Divider } from "antd";
import { COLORS, FONT } from "../../UI/Colors";
import styled from "styled-components";
import { usePostTicket } from "../../hooks/usePostTicket";
import { useSelector } from "react-redux";
import { getUser } from "../../reducers/auth.reducers";
import InfoIcon from "@mui/icons-material/Info";

export const ReportComponent: FC = () => {
  const [postTicketStatus, postTicket] = usePostTicket();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [commentary, setCommentary] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const categoryList = [
    {
      value: "Bug",
      label: t("report.bug")
    },
    {
      value: "Suggestion",
      label: t("report.suggestion")
    }
  ];

  const handleSendReport = () => {
    if (title === "" || commentary === "" || email === "" || category === "") {
      setError(true);
    } else {
      setError(false);
      postTicket({
        userID: user !== undefined ? user.id : "no-id",
        title: title,
        contactEmail: email,
        category: category,
        description: commentary,
        status: "open",
        messages: []
      });
      handleCloseModal;
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTitle("");
    setCommentary("");
    setEmail("");
    setCategory("");
    setError(false);
  };

  return (
    <>
      <Tooltip title={t("header.report")}>
        <ButtonReport onClick={() => setIsModalOpen(true)}>
          <InfoIcon style={{ color: "grey" }} />
        </ButtonReport>
      </Tooltip>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle>
          {t("report.titleModal")}
          <Divider style={{ margin: "10px 0" }} />
          <Box
            component="span"
            sx={{ position: "absolute", top: 0, right: 0, m: 1 }}
          >
            <IconButton
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <p>{t("report.reportModalText")}</p>
          <TextField
            fullWidth
            required
            error={error && title === ""}
            label={t("general.title")}
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value.replace(",", "."))}
            style={{ marginTop: "16px", borderRadius: "5px" }}
          />
          <TextField
            fullWidth
            required
            error={error && commentary === ""}
            label={t("general.description")}
            value={commentary}
            onChange={(e) => setCommentary(e.target.value)}
            style={{ marginTop: "16px", borderRadius: "5px" }}
          />
          <TextField
            fullWidth
            required
            error={error && email === ""}
            label={t("general.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginTop: "16px", borderRadius: "5px" }}
          />
          <TextField
            fullWidth
            select
            required
            error={error && category === ""}
            label={t("general.category")}
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            style={{ marginTop: "16px", borderRadius: "5px" }}
          >
            {categoryList.map((option: { value: string; label: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions style={{ marginTop: "16px" }}>
          <CancelButton onClick={handleCloseModal}>
            {t("general.cancel")}
          </CancelButton>
          <SendReportButton onClick={handleSendReport}>
            {t("general.send")}
          </SendReportButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CancelButton = styled.button`
  background-color: transparent;
  color: ${COLORS.text}
  padding: 10px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: ${COLORS.blue};
  }
`;

const SendReportButton = styled.button`
  background-color: ${COLORS.blue};
  color: ${COLORS.bg};
  padding 10px 20px;
  border-radius: 5px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:disabled {
    background-color: lightgray;
  }

  &:hover:enabled {
    background-color: ${COLORS.blueGreen};
    transition: all ease-in-out 0.2s;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }
`;

const ButtonReport = styled(Button)`
  width: 100%;
  height: 100%;
`;

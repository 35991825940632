import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import axios from "axios";
import OauthButton from "../../../components/Oauth/OauthButton";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { OauthServices } from "../Authentification.type";
import styled from "styled-components";
import { COLORS, FONT } from "../../../UI/Colors";
import {
  AuthentificationButton,
  AuthentificationInput,
  Column,
  DividerText,
  LinkToOtherAuthButton,
  OAuthButtonRow,
  IconInput,
  WrapperInput
} from "../Authentification.style";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "sonner";
import { CircularProgress } from "@mui/material";
import { useFetchUser } from "../../../hooks/useFetchUser";
import simpleLogo from "../../../assets/Trotter_logo.png";

const Register: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, fetchUser] = useFetchUser();

  async function register() {
    setLoading(true);
    if (password !== confirmPassword) {
      toast.error(t("register.passwordMismatch"));
      return;
    }
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URI}/auth/register`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER_URI,
          "Access-Control-Allow-Credentials": true
        },
        data: {
          email: email,
          username: email,
          password: password
        }
      });
      if (response.status == 201) {
        navigate("/validate-email");
      } else {
        toast.error(t("unexpectedError"));
      }
    } catch (e) {
      toast.error(t("unexpectedError"));
    }
    setLoading(false);
  }

  return (
    <RegisterWrapper>
      <ImageWrapper>
        <RegisterImage src="/login.png" />
        <LoginRedirection>
          <h1>{t("register.welcome")}</h1>
          <br />
          <h2>{t("register.member")}</h2>
          <LinkToOtherAuthButton onClick={() => navigate("/login")}>
            {t("general.logIn")}
          </LinkToOtherAuthButton>
        </LoginRedirection>
      </ImageWrapper>
      <FormWrapper>
        <Column>
          <LogoWrapper>
            <a href={"/"}>
              <PictureWrapper src={simpleLogo} alt={"Logo"} />
            </a>
          </LogoWrapper>
          <h2>{t("register.createAccount")}</h2>
          <WrapperInput>
            <IconInput>
              <MailOutlineIcon sx={{ color: "#BBBBBB" }} />
            </IconInput>
            <AuthentificationInput
              type="text"
              placeholder={t("general.email") as string}
              onChange={(e) => setEmail(e.target.value)}
              data-testid="emailInput"
            />
          </WrapperInput>
          <WrapperInput>
            <AuthentificationInput
              type={showPassword ? "text" : "password"}
              placeholder={t("general.password") as string}
              onChange={(e) => setPassword(e.target.value)}
              data-testid="passwordInput"
            />
            <IconInput>
              <TogglePasswordButton
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOffIcon style={{ color: "#BBBBBB" }} />
                ) : (
                  <VisibilityIcon style={{ color: "#BBBBBB" }} />
                )}
              </TogglePasswordButton>
            </IconInput>
          </WrapperInput>
          <WrapperInput>
            <AuthentificationInput
              type={showConfirmPassword ? "text" : "password"}
              placeholder={
                (t("general.confirm") + " " + t("general.password")) as string
              }
              onChange={(e) => setConfirmPassword(e.target.value)}
              data-testid="passwordConfirmInput"
            />
            <IconInput>
              <TogglePasswordButton
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <VisibilityOffIcon style={{ color: "#BBBBBB" }} />
                ) : (
                  <VisibilityIcon style={{ color: "#BBBBBB" }} />
                )}
              </TogglePasswordButton>
            </IconInput>
          </WrapperInput>
          <AuthentificationButton
            onClick={register}
            data-testid="submitRegister"
            disabled={loading}
          >
            {loading ? <CircularProgress /> : t("general.register")}
          </AuthentificationButton>
          <AlreadyHaveAnAccountText>
            <p>{t("register.member")}</p>
            <AlreadyHaveAnAccountClickableText
              onClick={() => navigate("/login")}
            >
              {t("general.logIn")}
            </AlreadyHaveAnAccountClickableText>
          </AlreadyHaveAnAccountText>
        </Column>
        <DividerText data-content={t("description.separator")} />
        <OAuthButtonRow>
          <OauthButton
            service={OauthServices.google}
            icon={<GoogleIcon style={{ width: 45, height: 45 }} />}
          />
          <OauthButton
            service={OauthServices.facebook}
            icon={<FacebookIcon style={{ width: 45, height: 45 }} />}
          />
          <OauthButton
            service={OauthServices.twitter}
            icon={<TwitterIcon style={{ width: 45, height: 45 }} />}
          />
          <OauthButton
            service={OauthServices.linkedin}
            icon={<LinkedInIcon style={{ width: 45, height: 45 }} />}
          />
        </OAuthButtonRow>
      </FormWrapper>
    </RegisterWrapper>
  );
};

const RegisterWrapper = styled.div`
  color: ${COLORS.text};
  font-family: ${FONT};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  @media screen and (max-width: 912px) {
    overflow-y: visible;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  filter: brightness(100%);
  width: 100%;
  height: 100%;
  border-radius: 0 200px 0 0;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const RegisterImage = styled.img`
  flex: 1;
  margin-left: auto;
  filter: brightness(100%);
  width: 100%;
  height: 100%;
  border-radius: 0 200px 0 0;
  z-index: 1;
  position: fixed;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const LoginRedirection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${COLORS.bg};
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  z-index: 10;
`;

const FormWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 912px) {
    height: 100%;
    padding: 0;
  }
`;

const TogglePasswordButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
`;

const LogoWrapper = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all ease-in-out 0.2s;

  &:hover {
    color: ${COLORS.links};
    scale: 1.04;
  }
`;

const PictureWrapper = styled.img`
  height: 100px;

  &:hover {
    color: ${COLORS.links};
  }
`;

const AlreadyHaveAnAccountText = styled.p`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  text-align: center;
  color: gray;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    margin-left: 180px;
  }
  @media screen and (max-width: 412px) {
    font-size: 14px;
    margin-left: 160px;
  }
  @media screen and (min-width: 1025px) {
    display: none;
  }
`;

const AlreadyHaveAnAccountClickableText = styled.p`
  color: ${COLORS.blue};
  cursor: pointer;
  display: flex;
`;

export default Register;

import React, { useState } from "react";
import { motion } from "framer-motion";
import "./Onboarding.scss";
import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Museum,
  EmojiNature,
  Nightlife,
  TagFaces,
  TempleBuddhist,
  AccountBalance
} from "@mui/icons-material";

const Onboarding = () => {
  const [stage, setStage] = useState<"welcome" | "selection" | "completed">(
    "welcome"
  );
  const [preferences, setPreferences] = useState<{
    q1: "Nature" | "Monument" | null;
    q2: "Street Art" | "Museum" | null;
    q3: "Night Life" | "Religion" | null;
  }>({
    q1: null,
    q2: null,
    q3: null
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <div className="onboardingBackground">
        <motion.div className="onboardingContent">
          {stage === "welcome" && (
            <>
              <motion.h1
                className="onboardingTitle"
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1 }}
              >
                {t("onBoarding.welcome")}
              </motion.h1>
              <motion.p
                className="onboardingText"
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1, delay: 1 }}
              >
                {t("onBoarding.customize")}
              </motion.p>
              <motion.div
                className="onboardingButton"
                onClick={() => setStage("selection")}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                transition={{ duration: 1, delay: 1.7 }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  sx={{ color: "white", borderColor: "white" }}
                >
                  {t("onBoarding.getStarted")}
                </Button>
              </motion.div>
            </>
          )}
          {stage === "completed" &&
            setTimeout(() => {
              navigate("/");
            }, 2500) && (
              <>
                <motion.h1
                  className="onboardingTitle"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  {t("onBoarding.done")}
                </motion.h1>
                <motion.p
                  className="onboardingText"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  {t("onBoarding.startTrotting")}
                </motion.p>
              </>
            )}
          {stage === "selection" && (
            <>
              <motion.div>
                <motion.h4
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1, delay: 0 }}
                >
                  {t("onBoarding.preferNatureOMonument")}
                </motion.h4>
                <motion.div className="flexRow">
                  <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 0.3 }}
                  >
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      className={
                        preferences.q1 === "Nature" ? "chosenOption" : ""
                      }
                    >
                      <IconButton
                        onClick={() => {
                          setPreferences({
                            ...preferences,
                            q1: preferences.q1 === "Nature" ? null : "Nature"
                          });
                        }}
                      >
                        <EmojiNature
                          sx={{
                            height: 60,
                            width: 60,
                            color:
                              preferences.q1 === "Nature"
                                ? "white"
                                : "lightgray"
                          }}
                        />
                      </IconButton>
                    </motion.div>
                  </motion.div>
                  <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 0.5 }}
                  >
                    {t("onBoarding.or")}
                  </motion.p>
                  <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 0.7 }}
                  >
                    <motion.div whileHover={{ scale: 1.1 }}>
                      <IconButton
                        onClick={() => {
                          setPreferences({
                            ...preferences,
                            q1:
                              preferences.q1 === "Monument" ? null : "Monument"
                          });
                        }}
                      >
                        <AccountBalance
                          sx={{
                            height: 60,
                            width: 60,
                            color:
                              preferences.q1 === "Monument"
                                ? "white"
                                : "lightgray"
                          }}
                        />
                      </IconButton>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div>
                <motion.h4
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1, delay: 0.9 }}
                >
                  {t("onBoarding.preferStreetArtOMuseum")}
                </motion.h4>
                <motion.div className="flexRow">
                  <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 1.1 }}
                  >
                    <motion.div whileHover={{ scale: 1.1 }}>
                      <IconButton
                        size="large"
                        onClick={() => {
                          setPreferences({
                            ...preferences,
                            q2:
                              preferences.q2 === "Street Art"
                                ? null
                                : "Street Art"
                          });
                        }}
                      >
                        <TagFaces
                          sx={{
                            height: 60,
                            width: 60,
                            color:
                              preferences.q2 === "Street Art"
                                ? "white"
                                : "lightgray"
                          }}
                        />
                      </IconButton>
                    </motion.div>
                  </motion.div>
                  <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 1.3 }}
                  >
                    {t("onBoarding.or")}
                  </motion.p>
                  <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 1.5 }}
                  >
                    <motion.div whileHover={{ scale: 1.1 }}>
                      <IconButton
                        onClick={() => {
                          setPreferences({
                            ...preferences,
                            q2: preferences.q2 === "Museum" ? null : "Museum"
                          });
                        }}
                      >
                        <Museum
                          sx={{
                            height: 60,
                            width: 60,
                            color:
                              preferences.q2 === "Museum"
                                ? "white"
                                : "lightgray"
                          }}
                        />
                      </IconButton>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div>
                <motion.h4
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1, delay: 1.5 }}
                >
                  {t("onBoarding.preferNightOSpiritual")}
                </motion.h4>
                <motion.div className="flexRow">
                  <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 1.7 }}
                  >
                    <motion.div whileHover={{ scale: 1.1 }}>
                      <IconButton
                        onClick={() => {
                          setPreferences({
                            ...preferences,
                            q3:
                              preferences.q3 === "Night Life"
                                ? null
                                : "Night Life"
                          });
                        }}
                      >
                        <Nightlife
                          sx={{
                            height: 60,
                            width: 60,
                            color:
                              preferences.q3 === "Night Life"
                                ? "white"
                                : "lightgray"
                          }}
                        />
                      </IconButton>
                    </motion.div>
                  </motion.div>
                  <motion.p
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 1.9 }}
                  >
                    {t("onBoarding.or")}
                  </motion.p>
                  <motion.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 1, delay: 2.1 }}
                  >
                    <motion.div whileHover={{ scale: 1.1 }}>
                      <IconButton
                        onClick={() => {
                          setPreferences({
                            ...preferences,
                            q3:
                              preferences.q3 === "Religion" ? null : "Religion"
                          });
                        }}
                      >
                        <TempleBuddhist
                          sx={{
                            height: 60,
                            width: 60,
                            color:
                              preferences.q3 === "Religion"
                                ? "white"
                                : "lightgray"
                          }}
                        />
                      </IconButton>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>
              <motion.div className="flexRow" style={{ paddingTop: "60px" }}>
                <motion.div
                  className="onboardingButton"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1 }}
                >
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{ color: "white", borderColor: "white" }}
                    onClick={() => setStage("completed")}
                  >
                    {t("general.skip")}
                  </Button>
                </motion.div>
                <motion.div
                  className="onboardingButton"
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  transition={{ duration: 1, delay: 2.3 }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    sx={{ color: "#6290C3", backgroundColor: "white" }}
                    onClick={() => {
                      setStage("completed");
                      localStorage.setItem(
                        "interests",
                        `${preferences.q1},${preferences.q2},${preferences.q3}`
                      );
                    }}
                    disabled={
                      !preferences.q1 && !preferences.q2 && !preferences.q3
                    }
                  >
                    {t("general.confirm")}
                  </Button>
                </motion.div>
              </motion.div>
            </>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default Onboarding;

import React, { ChangeEvent, FC, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { COLORS, FONT } from "../../UI/Colors";
import styled from "styled-components";
import { toast } from "sonner";

import { DatePicker, Divider } from "antd";
import dayjs from "dayjs";
import { Range } from "react-date-range";

import { useSelector } from "react-redux";
import { getUser } from "../../reducers/auth.reducers";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import { Close } from "@mui/icons-material";

interface EventCreateProps {
  isModalOpen: boolean;
  handleModalClose: () => void;
}

const EventCreate: FC<EventCreateProps> = ({
  isModalOpen,
  handleModalClose
}) => {
  const regexExp = /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/gi;
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [coords, setCoords] = useState<string>("");
  const [tags, setTags] = useState<string>("");
  const [maxParticipants, setMaxParticipants] = useState<number>(0);
  const [openRange, setOpenRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
      key: "selectionOpenPeriod"
    }
  ]);
  const [startRange, setStartRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
      key: "selectionStartingPeriod"
    }
  ]);

  const originalUser = useSelector(getUser);

  const { t } = useTranslation();

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleCoordsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCoords(e.target.value.replace(/ /g, ""));
  };

  const handleTagsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTags(e.target.value);
  };

  const handleMaxParticipantsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMaxParticipants(parseInt(e.target.value));
  };

  const ErrorHandler = () => {
    if (
      name === "" ||
      description === "" ||
      coords === "" ||
      !openRange[0].startDate ||
      !openRange[0].endDate ||
      !startRange[0].startDate ||
      !startRange[0].endDate
    ) {
      toast.error(t("event.fieldsError") || "");
      return false;
    }
    if (name.length > 128) {
      toast.error(t("event.tooMuchName") || "");
      return false;
    }
    if (description.length > 1500) {
      toast.error(t("event.tooMuchDesc") || "");
      return false;
    }
    if (maxParticipants < 0) {
      toast.error(t("event.maxParticipantsError") || "");
      return false;
    }
    if (regexExp.test(coords) === false) {
      toast.error(t("event.coordinatesError") || "");
      return false;
    }
    if (
      openRange[0].startDate > startRange[0].startDate ||
      openRange[0].endDate > startRange[0].endDate
    ) {
      toast.error(t("event.dateLogicError") || "");
      return false;
    }
  };

  const normalizeWS = (s: string) => {
    const r = s.match(/\S+/g);
    return r ? r.join(" ") : "";
  };

  const createEvent = async () => {
    if (
      ErrorHandler() === false ||
      !openRange[0].startDate ||
      !openRange[0].endDate ||
      !startRange[0].startDate ||
      !startRange[0].endDate
    ) {
      return;
    }

    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_URI}/event`,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        },
        data: {
          name: normalizeWS(name),
          description: normalizeWS(description),
          place: coords,
          host: originalUser.username,
          tags:
            tags === ""
              ? []
              : normalizeWS(tags)
                  .replace(", ", ",")
                  .replace(" ,", ",")
                  .split(","),
          maxSub: maxParticipants,
          participantList: [],
          start: startRange[0].startDate.toString(),
          end: startRange[0].endDate.toString(),
          open: openRange[0].startDate.toString(),
          close: openRange[0].endDate.toString()
        }
      });
      toast.success(t("event.success"));
    } catch (e) {
      console.error("An error occured while submitting the event: ", e);
      toast.error(t("event.error"));
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{ sx: { borderRadius: "10px", zIndex: 1 } }}
        open={isModalOpen}
        onClose={handleModalClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <b>{t("event.title")}</b>
          <Divider style={{ margin: "10px 0" }} />
          <Box
            component="span"
            sx={{ position: "absolute", top: 0, right: 0, m: 1 }}
          >
            <IconButton
              color="inherit"
              aria-label="close"
              onClick={handleModalClose}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: "visible" }}>
          <FormComponentWrapper>
            <TextField
              inputProps={{ maxLength: 128 }}
              placeholder={(t("event.name") as string) + " (128 max)"}
              onChange={handleNameChange}
            />
            <Form>
              <LeftColumn>
                <InfoText> {t("event.description")} </InfoText>
                <TextField
                  inputProps={{ maxLength: 1500 }}
                  placeholder={
                    (t("event.description") as string) + " (1500 max)"
                  }
                  onChange={handleDescriptionChange}
                />
                <Space />
                <InfoText> {t("event.participantsTitle")} </InfoText>
                <TextField
                  placeholder={t("event.maxParticipants") as string}
                  onChange={handleMaxParticipantsChange}
                />
                <Space />
                <Space />
                <InfoText> {t("event.openingDate")} </InfoText>
                <DateSelector
                  size={"middle"}
                  onChange={(rv) => {
                    if (!rv || !rv[0] || !rv[1]) return;
                    if (!rv[0]?.isBefore(rv[1]?.add(-7, "day"))) {
                      setOpenRange([
                        {
                          startDate: rv[0].toDate(),
                          endDate: rv[1].toDate(),
                          key: "selection"
                        }
                      ]);
                    }
                  }}
                  disabledDate={(date) => date.toDate() < new Date()}
                  value={[
                    dayjs(openRange[0].startDate),
                    dayjs(openRange[0].endDate)
                  ]}
                  getPopupContainer={(triggernode: HTMLElement) =>
                    triggernode.parentElement || document.body
                  }
                />
              </LeftColumn>
              <RightColumn>
                <InfoText> {t("event.coordsTitle")} </InfoText>
                <TextField
                  placeholder={t("event.coordinates") as string}
                  onChange={handleCoordsChange}
                />
                <Space />
                <InfoText> {t("event.tagsTitle")} </InfoText>
                <TextField
                  placeholder={t("event.tags") as string}
                  onChange={handleTagsChange}
                />
                <Space />
                <Space />
                <InfoText> {t("event.startDate")} </InfoText>
                <DateSelector
                  size={"middle"}
                  onChange={(rv) => {
                    if (!rv || !rv[0] || !rv[1]) return;
                    if (!rv[0]?.isBefore(rv[1]?.add(-7, "day"))) {
                      setStartRange([
                        {
                          startDate: rv[0].toDate(),
                          endDate: rv[1].toDate(),
                          key: "selection"
                        }
                      ]);
                    }
                  }}
                  disabledDate={(date) => date.toDate() < new Date()}
                  value={[
                    dayjs(startRange[0].startDate),
                    dayjs(startRange[0].endDate)
                  ]}
                  getPopupContainer={(triggernode: HTMLElement) =>
                    triggernode.parentElement || document.body
                  }
                />
              </RightColumn>
            </Form>
          </FormComponentWrapper>
        </DialogContent>
        <DialogActions style={{ marginTop: "16px" }}>
          <CancelButton onClick={() => handleModalClose()}>
            {t("general.cancel")}
          </CancelButton>
          <ConfirmButton onClick={() => createEvent()}>
            {t("event.submit")}
          </ConfirmButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const FormComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  align-items: center;
  align-content: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Space = styled.div`
  height: 28px;
  width: 100%;

  @media (max-width: 768px) {
    height: 20px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  font-weight: bold;
  font-size: 30px;
  font-family: ${FONT};
  color: ${COLORS.text};
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 5px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 5px;
`;

const InfoText = styled.div`
  align-self: flex-start;
  margin-left: 10px;
  margin-bottom: 2px;
  font-size: 16px;
  color: ${COLORS.text};
`;

const DateSelector = styled(DatePicker.RangePicker)`
  margin: 12px;
`;

const ConfirmButton = styled.button`
  margin-top: 34px;
  width: 180px;
  height: 48px;
  border: none;
  border-radius: 10px;
  background-color: ${COLORS.blue};
  color: ${COLORS.white};
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: ${COLORS.blue};
    scale: 1.06;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const CancelButton = styled.button`
  background-color: transparent;
  color: ${COLORS.text}
  padding: 10px;
  width: 140px;
  margin-top: 34px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: ${COLORS.blue};
  }
`;

export default EventCreate;

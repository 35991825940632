import { useWebClient } from "./useWebClient";
import { useTypedAsyncFn } from "./useTypedAsyncFn";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export const useRefuseFriends = () => {
  const webClient = useWebClient();
  const { t } = useTranslation();

  return useTypedAsyncFn(async (id) => {
    const promise = webClient.delete(`user/remove-friend/${id}`);
    toast.promise(promise, {
      success: t("friends.successRefuse"),
      error: t("friends.errorRefuse"),
      loading: t("friends.loadingRefuse")
    });
    await promise;
  }, []);
};

import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import "./notfound.scss";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <DesignContainer>
          <Number>4</Number>
          <div className="moon">
            <div className="disc" />
          </div>
          <Number>4</Number>
        </DesignContainer>
        <h3>{t("notfound.text")}</h3>
      </Container>
    </>
  );
};

export default NotFoundPage;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DesignContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const Number = styled.div`
  font-size: 8em;
`;

import {
  /* CircularProgress ,*/ CircularProgress,
  Typography
} from "@mui/material";
import { Divider, Progress, Rate, StepProps, Steps } from "antd";
import { addDays, format } from "date-fns";
import React, { Fragment, useState } from "react";
import BudgetComponent from "../Budget";
import { useFetchCityInfo } from "../../../hooks/useFetchCityInfo";
import { AttachMoney } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type TripSectionProps = {
  tripData: {
    lat: number | null;
    lon: number | null;
    cityName: string | null;
    bbox: number[] | null;
  };
  loading: boolean;
  startDate: Date;
  steps: StepProps[][];
  isShared?: boolean;
};

enum TAB {
  ITINERARY,
  INFO,
  BUDGET
}

const TripSection = ({
  loading,
  startDate,
  steps,
  isShared = false,
  tripData
}: TripSectionProps) => {
  const [tab, setTab] = useState<TAB>(TAB.ITINERARY);
  //eslint-disable-next-line
  const [cityInfo, setCityInfo] = useState<{
    country: { name: string; emoji: string; flag: string };
    hotelNight: { index: number; price: number };
    currency: { name: string; symbol: string; overUSD: number };
    englishProficiency: { index: number };
    population: {
      size: number;
      officialLanguages: string[];
      cityPictures: string[];
    };
    movieTicket: { price: number; index: number };
    lunch: { price: number; index: number };
    transportTicket: { price: number; index: number };
    drinks: { index: number; price: number };
  } | null>(null);
  const { t } = useTranslation();

  const [fetchCityInfoStatus, fetchCityInfo] = useFetchCityInfo();
  return (
    <div
      className={"mapSideMenu" + (isShared ? "Shared" : "")}
      id="mapSideMenu"
    >
      <div className="tabsContainer">
        <div onClick={() => setTab(TAB.ITINERARY)}>
          <Typography
            variant="h5"
            fontWeight={"bolder"}
            color={tab === TAB.ITINERARY ? "black" : "darkgray"}
          >
            {t("map.tripSectionItinerary")}
          </Typography>
        </div>
        <div
          onClick={async () => {
            setTab(TAB.INFO);
            if (!cityInfo && tripData.cityName)
              setCityInfo(
                await fetchCityInfo({
                  cityName: tripData.cityName,
                  lat: tripData.lat || 0,
                  lon: tripData.lon || 0
                })
              );
          }}
        >
          <Typography
            variant="h5"
            fontWeight={"bolder"}
            color={tab === TAB.INFO ? "black" : "darkgray"}
          >
            {t("map.tripSectionInfo")}
          </Typography>
        </div>
        <div onClick={() => setTab(TAB.BUDGET)}>
          <Typography
            variant="h5"
            fontWeight={"bolder"}
            color={tab === TAB.BUDGET ? "black" : "darkgray"}
          >
            {t("map.tripSectionBudget")}
          </Typography>
        </div>
      </div>
      {tab === TAB.ITINERARY
        ? loading
          ? null
          : Array.from({ length: steps.length }, (v, i) => i).map((day) => (
              <Fragment key={day}>
                <h3 /* itineraryDay={day} */>
                  {format(
                    addDays(startDate || new Date(), day),
                    "EEEE, do MMMM"
                  )}{" "}
                </h3>
                <Steps
                  items={steps.length ? steps[day] : []}
                  direction="vertical"
                  current={10000}
                />
                <Divider />
              </Fragment>
            ))
        : null}

      {tab === TAB.INFO ? (
        <div className="infoContainer">
          {fetchCityInfoStatus.loading ? (
            <CircularProgress />
          ) : cityInfo ? (
            <div id="countryInformations">
              <h3>
                {cityInfo.country.emoji} {tripData.cityName}{" "}
                {t("map.isCityFrom")} {cityInfo.country.name}{" "}
                {cityInfo.country.emoji}
              </h3>
              <img src={cityInfo.country.flag} alt="flag" height={40} />
              <div className="info">
                <h4>
                  {t("map.population")}: {cityInfo.population.size}
                </h4>
                <h4>
                  {t("map.officialLanguages")}:{" "}
                  {cityInfo.population.officialLanguages.join(", ")}
                </h4>
                <Divider />
                <div>
                  <h4>{t("map.proficiency")}:</h4>
                  <Progress
                    size={80}
                    type="dashboard"
                    percent={cityInfo.englishProficiency.index * 100}
                  />
                </div>
                <Divider />
              </div>
              <div className="info">
                <h4>
                  {t("map.currency")}: {cityInfo.currency.name} (
                  {cityInfo.currency.symbol})
                </h4>
                <h4>
                  1 {cityInfo.currency.symbol} = {cityInfo.currency.overUSD} USD
                </h4>
              </div>
              <Divider />
              <div className="info">
                <h3>{t("map.costLiving")}</h3>
                <div className="flexRowInfo">
                  <h4>{t("map.hotelNight")}:</h4>{" "}
                  <span>
                    {cityInfo.hotelNight.price}
                    {cityInfo.currency.symbol}
                  </span>
                  <Rate
                    allowHalf
                    style={{
                      color:
                        cityInfo.hotelNight.index > 0.7
                          ? "green"
                          : cityInfo.hotelNight.index > 0.3
                          ? "orange"
                          : "red"
                    }}
                    disabled
                    defaultValue={5 * (1 - cityInfo.hotelNight.index)}
                    character={<AttachMoney />}
                  />
                </div>
                <div className="flexRowInfo">
                  <h4>{t("map.transportTicket")}:</h4>{" "}
                  <span>
                    {cityInfo.transportTicket.price}
                    {cityInfo.currency.symbol}
                  </span>
                  <Rate
                    allowHalf
                    style={{
                      color:
                        cityInfo.transportTicket.index > 0.7
                          ? "green"
                          : cityInfo.transportTicket.index > 0.3
                          ? "orange"
                          : "red"
                    }}
                    disabled
                    defaultValue={5 * (1 - cityInfo.transportTicket.index)}
                    character={<AttachMoney />}
                  />
                </div>
                <div className="flexRowInfo">
                  <h4>{t("map.lunch")}:</h4>{" "}
                  <span>
                    {cityInfo.lunch.price}
                    {cityInfo.currency.symbol}
                  </span>
                  <Rate
                    allowHalf
                    style={{
                      color:
                        cityInfo.lunch.index > 0.7
                          ? "green"
                          : cityInfo.lunch.index > 0.3
                          ? "orange"
                          : "red"
                    }}
                    disabled
                    defaultValue={5 * (1 - cityInfo.lunch.index)}
                    character={<AttachMoney />}
                  />
                </div>
                <div className="flexRowInfo">
                  <h4>{t("map.drinks")}:</h4>{" "}
                  <span>
                    {cityInfo.drinks.price}
                    {cityInfo.currency.symbol}
                  </span>
                  <Rate
                    allowHalf
                    style={{
                      color:
                        cityInfo.drinks.index > 0.7
                          ? "green"
                          : cityInfo.drinks.index > 0.3
                          ? "orange"
                          : "red"
                    }}
                    disabled
                    defaultValue={5 * (1 - cityInfo.drinks.index)}
                    character={<AttachMoney />}
                  />
                </div>
                <div className="flexRowInfo">
                  <h4>{t("map.movieTicket")}:</h4>{" "}
                  <span>
                    {cityInfo.movieTicket.price}
                    {cityInfo.currency.symbol}
                  </span>
                  <Rate
                    allowHalf
                    style={{
                      color:
                        cityInfo.movieTicket.index > 0.7
                          ? "green"
                          : cityInfo.movieTicket.index > 0.3
                          ? "orange"
                          : "red"
                    }}
                    disabled
                    defaultValue={5 * (1 - cityInfo.movieTicket.index)}
                    character={<AttachMoney />}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {tab === TAB.BUDGET ? (
        <BudgetComponent
          cityName={tripData.cityName || ""}
          lat={tripData.lat || 0}
          lon={tripData.lon || 0}
          tripLength={steps.length}
        />
      ) : null}
    </div>
  );
};

export default TripSection;

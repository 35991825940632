import {
  Checkbox,
  Chip,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from "@mui/material";
import { Popper } from "@mui/base";
import {
  AccountBalance,
  AutoAwesome,
  Church,
  EmojiNature,
  LocalActivity,
  Museum,
  MusicNote,
  NightsStay,
  TagFaces
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { COLORS, FONT } from "../../../UI/Colors";
import { useTranslation } from "react-i18next";

const InterestsFilter = () => {
  const { t } = useTranslation();
  const [openInterests, setOpenInterests] = useState(false);
  const interestsRef = useRef(null);
  const [interests, setInterests] = React.useState<string[]>([]);

  const interestsIcons: Record<string, React.ReactNode> = {
    [t("map.religion")]: <Church />,
    [t("map.event")]: <LocalActivity />,
    [t("map.nature")]: <EmojiNature />,
    [t("map.museum")]: <Museum />,
    [t("map.art")]: <AutoAwesome />,
    [t("map.music")]: <MusicNote />,
    [t("map.nightLife")]: <NightsStay />,
    [t("map.streetArt")]: <TagFaces />,
    [t("map.monument")]: <AccountBalance />
  };

  useEffect(() => {
    const lsInterests = localStorage.getItem("interests");
    if (lsInterests) {
      setInterests(lsInterests.split(","));
    }

    return () => {
      if (interests.length) {
        localStorage.setItem("interests", interests.join(","));
      }
    };
  }, []);

  return (
    <>
      <Chip
        label={t("interests.title")}
        onClick={() => setOpenInterests((prevState) => !prevState)}
        ref={interestsRef}
      />
      <Popper
        open={openInterests}
        anchorEl={interestsRef.current}
        placement="bottom-start"
        disablePortal
        style={{ zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={() => setOpenInterests(false)}>
          <Paper>
            <List>
              {Object.entries(interestsIcons).map(([key, icon]) => (
                <ListItem key={key}>
                  <ListItemButton
                    onClick={() =>
                      setInterests((prev) => {
                        if (prev.find((x) => x === key)) {
                          return prev.filter((x) => x !== key);
                        } else {
                          return [...prev, key];
                        }
                      })
                    }
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={key} />
                    <Checkbox checked={!!interests.find((x) => x === key)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <SaveInterestsButton
                onClick={() => {
                  localStorage.setItem("interests", interests.join(","));
                  toast.success("Interests saved successfully");
                  setOpenInterests(false);
                }}
              >
                {t("interests.save")}
              </SaveInterestsButton>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const SaveInterestsButton = styled.button`
  width: fit-content;
  height: 40px;
  background-color: ${COLORS.blue};
  color: ${COLORS.bg};
  border-radius: 5px;
  margin: 15px;
  padding: 10px 14px;
  font-family: ${FONT};
  font-size: 16px;
  font-weight: bold;
  align-items: flex-end;

  &:hover {
    background-color: ${COLORS.blueGreen};
    transition: all ease-in-out 0.2s;
  }
`;

export default InterestsFilter;

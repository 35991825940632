/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef, useEffect, useState } from "react";
import "./Map.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Range } from "react-date-range";
import { useSelector } from "react-redux";
import {
  GeolocateControl,
  Layer,
  Marker,
  Map,
  MapRef,
  NavigationControl,
  ScaleControl,
  Source
} from "react-map-gl";
import { FeatureCollection } from "geojson";
import { Popup, MapLayerMouseEvent } from "mapbox-gl";
import Routes from "./Routes";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  TextField,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { addDays, format } from "date-fns";
import { getSearchInput } from "../../reducers/search.reducers";
import {
  BaseMapPropsDefault,
  mapGjsonToSteps,
  weekColors
} from "./Maps.helpers";
import { GeoJsonRes, TransportType } from "./Maps.type";
import styled from "styled-components";
import { Geometry } from "@turf/helpers";
import {
  DatePicker,
  Button,
  Steps,
  Progress,
  Rate,
  Table,
  Divider
} from "antd";
import StepMarker from "./StepMarker";
import { useGenerateItinerary } from "../../hooks/useGenerateItinerary";
import dayjs from "dayjs";
import { useSaveTrip } from "../../hooks/useSaveTrip";
import { useGetTrips } from "../../hooks/useGetTrips";
import { Trip, getSavedTrips } from "../../reducers/trips.reducers";
import { useSearchParams } from "react-router-dom";
import { useShareTrip } from "../../hooks/useShareTrip";
import { useTranslation } from "react-i18next";
import { COLORS, FONT } from "../../UI/Colors";
import simpleLogo from "../../assets/Trotter_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faCloudArrowUp,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { AddHome, AttachMoney } from "@mui/icons-material";
import BudgetComponent from "./Budget";
import { useFetchCityInfo } from "../../hooks/useFetchCityInfo";
import TransportFilter from "./components/TransportFilter";
import InterestsFilter from "./components/InterestsFilter";
import { toast } from "sonner";

type PageContainerProps = {
  isTablet: boolean;
};

const NewBaseMap: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mapRef = React.useRef<MapRef>(null);
  const [dropoffs, setDropoffs] = useState<{
    [id: string]: FeatureCollection;
  }>({});
  const [routes, setRoutes] = useState<{
    [id: string]: FeatureCollection;
  }>({});
  const searchState = useSelector(getSearchInput);
  const trips = useSelector(getSavedTrips);
  const [searchParams] = useSearchParams();
  const tripId = searchParams.get("id");
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [openOverview, setOpenOverview] = useState<boolean>(true);
  const [openTrips, setOpenTrips] = useState<boolean>(true);
  const [openBudget, setOpenBudget] = useState<boolean>(true);
  const [openInformation, setOpenInformation] = useState<boolean>(true);
  const [openEnglish, setOpenEnglish] = useState<boolean>(true);
  const [openCurrency, setOpenCurrency] = useState<boolean>(true);
  const [openCost, setOpenCost] = useState<boolean>(true);
  const [openDays, setOpenDays] = useState<boolean[]>([]);
  const informationRef = useRef<HTMLDivElement>(null);
  const englishRef = useRef<HTMLDivElement>(null);
  const currencyRef = useRef<HTMLDivElement>(null);
  const costRef = useRef<HTMLDivElement>(null);
  const daysRefs = useRef<HTMLDivElement[]>([]);
  const budgetRef = useRef<HTMLDivElement>(null);

  const scrollToDiv = (myRef: React.RefObject<HTMLDivElement>) => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const tripsColumns = [
    {
      title: t("map.tripLoadModalContentCity"),
      dataIndex: "cityName",
      key: "cityName"
    },
    {
      title: t("map.tripLoadModalContentStartDate"),
      dataIndex: "startDate",
      key: "startDate"
    },
    {
      title: t("map.tripLoadModalContentEndDate"),
      dataIndex: "endDate",
      key: "endDate"
    },
    {
      title: t("map.tripLoadModalContentShared"),
      dataIndex: "",
      key: "share",
      render: (record: Trip) => (
        <Button
          onClick={() => {
            shareTrip(record.id);
          }}
          loading={shareTripStatus.loading}
          disabled={shareTripStatus.loading}
        >
          {" "}
          Share{" "}
        </Button>
      )
    },
    {
      title: t("map.tripLoadModalContentAction"),
      dataIndex: "",
      key: "x",
      // eslint-disable-next-line
      render: (record: Trip) => (
        <Button
          onClick={() => {
            setIsComputing(true);
            setTripData({
              bbox: null,
              cityName: record.cityName,
              lat: null,
              lon: null
            });
            setRange([
              {
                endDate: new Date(record.endDate),
                startDate: new Date(record.startDate),
                key: "selection"
              }
            ]);
            computeMapData(record.tripData, false);
            toggleIsTripLoadModalOpen(false);
          }}
        >
          Load
        </Button>
      )
    }
  ];

  const [length, setLength] = useState<number>(BaseMapPropsDefault.length);
  const [itineraryDay, setItineraryDay] = useState<number>(0);
  const [tripData, setTripData] = useState<{
    lat: number | null;
    lon: number | null;
    cityName: string | null;
    bbox: number[] | null;
  }>(
    searchState.cityName
      ? (searchState as {
          lat: number;
          lon: number;
          cityName: string;
          bbox: number[];
        })
      : {
          lat: BaseMapPropsDefault.lat,
          lon: BaseMapPropsDefault.lng,
          cityName: null,
          bbox: null
        }
  );
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
      key: "selection"
    }
  ]);
  const [markers, setMarkers] = useState<React.ReactElement[]>([]);
  const [hotel, setHotel] = useState<React.ReactElement[]>([]);
  const [cursor, setCursor] = useState<string>("grab");
  const [isHotelSelectionActivated, setIsHotelSelectionActivated] =
    useState<boolean>(false);
  const [isTripSaveModalOpen, toggleIsTripSaveModalOpen] =
    useState<boolean>(false);
  const [isTripLoadModalOpen, toggleIsTripLoadModalOpen] =
    useState<boolean>(false);
  const [isComputing, setIsComputing] = useState<boolean>(false);

  const [transportMode, setTransportMode] = useState<TransportType>(
    TransportType.WALKING
  );
  const [generateItineraryStatus, generateItinerary] = useGenerateItinerary();
  const [saveTripStatus, saveTrip] = useSaveTrip();
  const [getTripsStatus, getTrips] = useGetTrips();
  const [shareTripStatus, shareTrip] = useShareTrip();
  const [currentTrip, setCurrentTrip] = useState<GeoJsonRes | null>(null);
  const [jsonData, setJsonData] = useState<{
    features: {
      geometry: {
        coordinates: number[];
      };
      properties: {
        name: string;
        href: string;
        bbox: number[];
      };
    }[];
  } | null>(null);
  const [cityInfo, setCityInfo] = useState<{
    country: { name: string; emoji: string; flag: string };
    hotelNight: { index: number; price: number };
    currency: { name: string; symbol: string; overUSD: number };
    englishProficiency: { index: number };
    population: {
      size: number;
      officialLanguages: string[];
      cityPictures: string[];
    };
    movieTicket: { price: number; index: number };
    lunch: { price: number; index: number };
    transportTicket: { price: number; index: number };
    drinks: { index: number; price: number };
  } | null>(null);
  const [fetchCityInfoStatus, fetchCityInfo] = useFetchCityInfo();

  useEffect(() => {
    const fetchData = async () => {
      // Fetch the JSON file using the relative path
      const response = await fetch("/data.geojson");
      const data = await response.json();
      setJsonData(data);
    };

    fetchData();
    if (tripId) {
      loadTripFromId(tripId);
    }
  }, []);

  const handleMapClick = async (e: MapLayerMouseEvent) => {
    if (!e.features || !e.features[0]) return;
    if (e.features[0].layer.id === "city-layer") {
      setTripData({
        cityName: e.features[0].properties?.name || "",
        lat: (e.features[0].geometry as any).coordinates[1] || 0,
        lon: (e.features[0].geometry as any).coordinates[0] || 0,
        bbox: e.features[0].properties?.bbox || []
      });
      mapRef.current?.flyTo({
        center: [
          (e.features[0].geometry as any).coordinates[0] || 0,
          (e.features[0].geometry as any).coordinates[1] || 0
        ],
        zoom: 10,
        duration: 1000
      });
    } else if (e.features[0].layer.id.includes("routeline-active")) {
      setItineraryDay(
        parseInt(e.features[0].layer.id.replace("routeline-active", ""))
      );
    }
  };

  useEffect(() => {
    if (isTripLoadModalOpen) getTrips();
  }, [isTripLoadModalOpen]);

  const handleMapDblClick = async (e: MapLayerMouseEvent) => {
    if (isHotelSelectionActivated) {
      const newMarker = (
        <Marker
          key={markers.length}
          longitude={e.lngLat.lng}
          latitude={e.lngLat.lat}
        >
          <HotelMarker
            width={36}
            height={36}
            alt={"hotel"}
            src={
              "https://em-content.zobj.net/source/microsoft-teams/337/house_1f3e0.png"
            }
          />
        </Marker>
      );
      setHotel([newMarker]);
      setTripData((prev) => ({
        cityName: prev.cityName,
        lat: e.lngLat.lat,
        lon: e.lngLat.lng,
        bbox: [
          e.lngLat.lng - 0.12,
          e.lngLat.lat - 0.12,
          e.lngLat.lng + 0.12,
          e.lngLat.lat + 0.12
        ]
      }));
      setIsHotelSelectionActivated(false);
    }
  };

  const loadTripFromId = async (id: string) => {
    let localTrips = trips;
    if (!localTrips.length) {
      localTrips = await getTrips();
    }
    const trip = localTrips.find((x) => x.id === id);
    if (!trip) {
      return;
    } else {
      const endDate = new Date(0);
      const startDate = new Date(0);
      endDate.setUTCSeconds(trip.endDate / 1000);
      startDate.setUTCSeconds(trip.startDate / 1000);
      setIsComputing(true);
      setTripData({
        bbox: null,
        cityName: trip.cityName,
        lat: null,
        lon: null
      });
      setRange([
        {
          endDate: new Date(endDate),
          startDate: new Date(startDate),
          key: "selection"
        }
      ]);
      computeMapData(trip.tripData, false);
      setCurrentTrip(trip.tripData);
      setTimeout(() => {
        mapRef.current?.flyTo({
          center: [
            trip.tripData.features[0].features[0].geometry
              .coordinates[0] as unknown as number,
            trip.tripData.features[0].features[0].geometry
              .coordinates[1] as unknown as number
          ],
          zoom: 12
        });
      }, 500);
    }
  };

  useEffect(() => {
    if (range.length === 0 || !range[0].endDate || !range[0].startDate) return;
    const diffTime = Math.abs(
      range[0]?.endDate?.getTime() - range[0]?.startDate?.getTime()
    );
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    setLength(diffDays);
  }, [range]);

  const computeMapData = (resJson: GeoJsonRes, status: boolean) => {
    setMarkers([]);
    setDropoffs({});
    setRoutes({});
    if (status === false) {
      mapRef.current?.flyTo({
        center: [
          resJson.features[0].features[0].geometry
            .coordinates[0] as unknown as number,
          resJson.features[0].features[0].geometry
            .coordinates[1] as unknown as number
        ],
        zoom: 12
      });
      setTripData({
        bbox: null,
        cityName: "Paris",
        lat: null,
        lon: null
      });
    }

    if (resJson.features) {
      for (const features of resJson.features) {
        const i = resJson.features.indexOf(features);
        if (!features) continue;
        setDropoffs((old) => ({
          ...old,
          [i]: features as unknown as FeatureCollection
        }));
      }
    }

    if (resJson.routes) {
      for (const routes1 of resJson.routes) {
        const i = resJson.routes.indexOf(routes1);
        if (!routes1) continue;
        setRoutes((old) => ({
          ...old,
          [i]: routes1.route
        }));
      }
    }

    setIsComputing(false);
  };

  const fetchCoordinates = async (
    lng: number,
    lat: number,
    transportMode: TransportType,
    //eslint-disable-next-line
    bbox: number[]
  ) => {
    if (!lng && !lat) return;
    try {
      const resJsonWithStatus = await generateItinerary({
        lon: lng ?? 0,
        lat: lat ?? 0,
        days: length,
        transportMean: transportMode,
        bbox: [lng - 0.12, lat - 0.12, lng + 0.12, lat + 0.12]
      });
      handleCityInfo();
      setIsComputing(true);
      setCurrentTrip(resJsonWithStatus[1]);
      computeMapData(resJsonWithStatus[1], resJsonWithStatus[0]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setMarkers([]);
    dropoffs[itineraryDay]?.features?.forEach((element, i: number) => {
      const pic = element?.properties?.photos?.length
        ? element?.properties?.photos[0].prefix +
          "original" +
          element?.properties?.photos[0].suffix
        : null;
      setMarkers((old) => [
        ...old,
        <Marker
          key={element?.properties?.name + i}
          latitude={
            (element.geometry as Partial<Geometry> & { coordinates: number[] })
              .coordinates[1]
          }
          longitude={
            (element.geometry as Partial<Geometry> & { coordinates: number[] })
              .coordinates[0]
          }
          popup={new Popup({
            offset: 20,
            className: "markerPopup",
            anchor: "bottom",
            closeOnMove: true,
            closeOnClick: true
          }).setHTML(
            `<h3>${
              element?.properties?.name
            }</h3><img width="200" height="100" src="${`${
              pic ||
              "https://www.freeiconspng.com/thumbs/ghost-icon/ghost-icon-14.png"
            }`}" />`
          )}
        >
          {/* <CustomMarker element={element as Feature} /> */}
          <StepMarker dayIndex={itineraryDay} featureIndex={i} />
        </Marker>
      ]);
    });
  }, [dropoffs, itineraryDay]);

  const handleCityInfo = async () => {
    console.log(tripData);
    if (!cityInfo && tripData.cityName)
      setCityInfo(
        await fetchCityInfo({
          cityName: tripData.cityName,
          lat: tripData.lat || 0,
          lon: tripData.lon || 0
        })
      );
  };

  useEffect(() => {
    if (
      !generateItineraryStatus.loading &&
      !!tripData.cityName &&
      !isComputing
    ) {
      fetchCoordinates(
        tripData.lon as number,
        tripData.lat as number,
        transportMode,
        tripData.bbox || []
      );
    }
    setItineraryDay(0);
  }, [tripData, transportMode]);

  useEffect(() => {
    handleCityInfo();
  }, []);

  const handleHomestayClick = () => {
    window.open(
      "https://affiliate.homestay.com?utm_medium=affiliate&utm_source=trotter-home",
      "_blank"
    );
    toast.success(
      "Thank you for using our partner Homestay to book your stay!"
    );
  };

  return (
    <PageContainer isTablet={isTablet}>
      {generateItineraryStatus.loading && (
        <LoadingContainer isTablet={isTablet}>
          <LogoWrapper>
            <PictureWrapper src={simpleLogo} alt={"Logo"} />
          </LogoWrapper>
        </LoadingContainer>
      )}
      <DetailsContainer isTablet={isTablet}>
        <TimeLineContainer>
          <OverviewButton
            onClick={() => setOpenOverview((prevState) => !prevState)}
          >
            <FontAwesomeIcon
              width={25}
              icon={openOverview ? faChevronDown : faChevronRight}
            />
            <p>{t("map.overview")}</p>
          </OverviewButton>
          {openOverview && (
            <TimeLineTextContainer>
              <TimeLineText
                onClick={() => {
                  scrollToDiv(informationRef);
                }}
              >
                {t("map.information")}
              </TimeLineText>
              <TimeLineText
                onClick={() => {
                  scrollToDiv(englishRef);
                }}
              >
                {t("map.proficiency")}
              </TimeLineText>
              <TimeLineText
                onClick={() => {
                  scrollToDiv(currencyRef);
                }}
              >
                {t("map.currency")}
              </TimeLineText>
              <TimeLineText
                onClick={() => {
                  scrollToDiv(costRef);
                }}
              >
                {t("map.costLiving")}
              </TimeLineText>
            </TimeLineTextContainer>
          )}
          <TimeLineButton
            onClick={() => setOpenTrips((prevState) => !prevState)}
          >
            <FontAwesomeIcon
              width={25}
              icon={openTrips ? faChevronDown : faChevronRight}
            />
            <p>{t("map.tripSectionItinerary")}</p>
          </TimeLineButton>
          {openTrips && (
            <TimeLineTextContainer>
              {Array.from(
                {
                  length: mapGjsonToSteps(
                    currentTrip,
                    !!hotel.length,
                    transportMode,
                    mapRef,
                    setItineraryDay
                  ).length
                },
                (v, i) => i
              ).map((day, index) => (
                <TimeLineText
                  key={day}
                  onClick={() => {
                    scrollToDiv({ current: daysRefs.current[index] });
                  }}
                >
                  {format(
                    addDays(range[0].startDate || new Date(), day),
                    "EEEE, do MMMM"
                  )}{" "}
                </TimeLineText>
              ))}
            </TimeLineTextContainer>
          )}
          <TimeLineButton
            onClick={() => setOpenBudget((prevState) => !prevState)}
          >
            <FontAwesomeIcon
              width={25}
              icon={openBudget ? faChevronDown : faChevronRight}
            />
            <p>{t("map.tripSectionBudget")}</p>
          </TimeLineButton>
          {openBudget && (
            <TimeLineTextContainer>
              <TimeLineText
                onClick={() => {
                  scrollToDiv(budgetRef);
                }}
              >
                {t("map.view")}
              </TimeLineText>
            </TimeLineTextContainer>
          )}
        </TimeLineContainer>
        <InformationContainer isTablet={isTablet}>
          <ResearchBarContainer>
            <Autocomplete
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
                borderColor: "#cecece"
              }}
              disablePortal
              id="combo-box-demo"
              options={jsonData?.features ?? []}
              getOptionLabel={(option) => option.properties.name}
              sx={{ width: 250 }}
              isOptionEqualToValue={(option, value) =>
                option.properties.name === value.properties.name
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setTripData({
                    cityName: newValue.properties.name,
                    lat: newValue.geometry.coordinates[1],
                    lon: newValue.geometry.coordinates[0],
                    bbox: newValue.properties.bbox
                  });
                  mapRef.current?.flyTo({
                    center: [
                      newValue.geometry.coordinates[0],
                      newValue.geometry.coordinates[1]
                    ],
                    zoom: 12
                  });
                  handleCityInfo();
                }
              }}
              renderInput={(params) => (
                <TextField
                  style={{ borderRadius: "5px", borderColor: "#cecece" }}
                  {...params}
                  label={t("map.tripLoadModalContentCity")}
                  key={params.id}
                />
              )}
            />
            <div style={{ width: "280px" }}>
              <DatePicker.RangePicker
                size={"middle"}
                style={{
                  backgroundColor: `${COLORS.white}`,
                  borderColor: "#bbbbbb",
                  borderRadius: "5px"
                }}
                placement="bottomLeft"
                onChange={(rv) => {
                  if (!rv || !rv[0] || !rv[1]) return;
                  if (!rv[0]?.isBefore(rv[1]?.add(-7, "day"))) {
                    setRange([
                      {
                        startDate: rv[0].toDate(),
                        endDate: rv[1].toDate(),
                        key: "selection"
                      }
                    ]);
                  }
                }}
                disabledDate={(date) => date.toDate() < new Date()}
                value={[dayjs(range[0].startDate), dayjs(range[0].endDate)]}
              />
            </div>
            <ContainerComponent>
              <SaveTripButton
                onClick={() => toggleIsTripSaveModalOpen((prev) => !prev)}
              >
                <FontAwesomeIcon width={25} icon={faDownload} />
              </SaveTripButton>
              <SaveTripButton
                onClick={() => toggleIsTripLoadModalOpen((prev) => !prev)}
              >
                <FontAwesomeIcon width={25} icon={faCloudArrowUp} />
              </SaveTripButton>
              <SaveTripButton onClick={handleHomestayClick}>
                <img
                  src="/homestay.png"
                  alt="homestay"
                  width={48}
                  height={48}
                />
              </SaveTripButton>
            </ContainerComponent>
          </ResearchBarContainer>
          <FiltersContainer>
            <InterestsFilter />
            <TransportFilter
              transportMode={transportMode}
              setTransportMode={setTransportMode}
            />
            <Chip
              label={t("map.addHotel")}
              onDelete={() => setIsHotelSelectionActivated((state) => !state)}
              deleteIcon={
                <AddHome
                  sx={{ color: isHotelSelectionActivated ? "blue" : null }}
                />
              }
            />
          </FiltersContainer>
          <ContentContainer isTablet={isTablet}>
            {fetchCityInfoStatus.loading ? (
              <LogoWrapper>
                <PictureWrapper src={simpleLogo} alt={"Logo"} />
              </LogoWrapper>
            ) : (
              <div>
                <TravelText>
                  {t("map.tripTo")}
                  {tripData.cityName || "..."}
                </TravelText>
                <Separator />
                <div>
                  <SubtitleText
                    ref={informationRef}
                    onClick={() =>
                      setOpenInformation((prevState) => !prevState)
                    }
                  >
                    <FontAwesomeIcon
                      width={25}
                      icon={openInformation ? faChevronDown : faChevronRight}
                    />
                    <p>{t("map.information")}</p>
                  </SubtitleText>
                  {openInformation &&
                    (cityInfo ? (
                      <StepsContainer>
                        <h4>
                          Population: {cityInfo && cityInfo?.population.size}
                        </h4>
                        <h4>
                          Official languages:{" "}
                          {cityInfo &&
                            cityInfo?.population.officialLanguages.join(", ")}
                        </h4>
                      </StepsContainer>
                    ) : (
                      <StepsContainer>{t("map.noResultsCity")}</StepsContainer>
                    ))}
                </div>
                <div>
                  <SubtitleText
                    ref={englishRef}
                    onClick={() => setOpenEnglish((prevState) => !prevState)}
                  >
                    <FontAwesomeIcon
                      width={25}
                      icon={openEnglish ? faChevronDown : faChevronRight}
                    />
                    <p>{t("map.proficiency")}</p>
                  </SubtitleText>
                  {openEnglish &&
                    (cityInfo ? (
                      <StepsContainer>
                        <Progress
                          size={80}
                          type="dashboard"
                          percent={cityInfo.englishProficiency.index * 100}
                        />
                      </StepsContainer>
                    ) : (
                      <StepsContainer>
                        {t("map.noResultsProficiency")}
                      </StepsContainer>
                    ))}
                </div>
                <div>
                  <SubtitleText
                    ref={currencyRef}
                    onClick={() => setOpenCurrency((prevState) => !prevState)}
                  >
                    <FontAwesomeIcon
                      width={25}
                      icon={openCurrency ? faChevronDown : faChevronRight}
                    />
                    <p>{t("map.currency")}</p>
                  </SubtitleText>
                  {openCurrency &&
                    (cityInfo ? (
                      <StepsContainer>
                        <h4>
                          Currency: {cityInfo.currency.name} (
                          {cityInfo.currency.symbol})
                        </h4>
                        <h4>
                          1 {cityInfo.currency.symbol} ={" "}
                          {cityInfo.currency.overUSD} USD
                        </h4>
                      </StepsContainer>
                    ) : (
                      <StepsContainer>
                        {t("map.noResultsCurrency")}
                      </StepsContainer>
                    ))}
                </div>
                <div>
                  <SubtitleText
                    ref={costRef}
                    onClick={() => setOpenCost((prevState) => !prevState)}
                  >
                    <FontAwesomeIcon
                      width={25}
                      icon={openCost ? faChevronDown : faChevronRight}
                    />
                    <p>{t("map.costLiving")}</p>
                  </SubtitleText>
                  {openCost &&
                    (cityInfo ? (
                      <StepsContainer>
                        <CostOfLivingContainer>
                          <h4>{t("map.hotelNight")}</h4>{" "}
                          <span>
                            {cityInfo.hotelNight.price}
                            {cityInfo.currency.symbol}
                          </span>
                          <Rate
                            allowHalf
                            style={{
                              color:
                                cityInfo.hotelNight.index > 0.7
                                  ? "green"
                                  : cityInfo.hotelNight.index > 0.3
                                  ? "orange"
                                  : "red"
                            }}
                            disabled
                            defaultValue={5 * (1 - cityInfo.hotelNight.index)}
                            character={<AttachMoney />}
                          />
                        </CostOfLivingContainer>
                        <CostOfLivingContainer>
                          <h4>{t("map.transportTicket")}</h4>{" "}
                          <span>
                            {cityInfo.transportTicket.price}
                            {cityInfo.currency.symbol}
                          </span>
                          <Rate
                            allowHalf
                            style={{
                              color:
                                cityInfo.transportTicket.index > 0.7
                                  ? "green"
                                  : cityInfo.transportTicket.index > 0.3
                                  ? "orange"
                                  : "red"
                            }}
                            disabled
                            defaultValue={
                              5 * (1 - cityInfo.transportTicket.index)
                            }
                            character={<AttachMoney />}
                          />
                        </CostOfLivingContainer>
                        <CostOfLivingContainer>
                          <h4>{t("map.lunch")}</h4>{" "}
                          <span>
                            {cityInfo.lunch.price}
                            {cityInfo.currency.symbol}
                          </span>
                          <Rate
                            allowHalf
                            style={{
                              color:
                                cityInfo.lunch.index > 0.7
                                  ? "green"
                                  : cityInfo.lunch.index > 0.3
                                  ? "orange"
                                  : "red"
                            }}
                            disabled
                            defaultValue={5 * (1 - cityInfo.lunch.index)}
                            character={<AttachMoney />}
                          />
                        </CostOfLivingContainer>
                        <CostOfLivingContainer>
                          <h4>{t("map.drinks")}</h4>{" "}
                          <span>
                            {cityInfo.drinks.price}
                            {cityInfo.currency.symbol}
                          </span>
                          <Rate
                            allowHalf
                            style={{
                              color:
                                cityInfo.drinks.index > 0.7
                                  ? "green"
                                  : cityInfo.drinks.index > 0.3
                                  ? "orange"
                                  : "red"
                            }}
                            disabled
                            defaultValue={5 * (1 - cityInfo.drinks.index)}
                            character={<AttachMoney />}
                          />
                        </CostOfLivingContainer>
                        <CostOfLivingContainer>
                          <h4>{t("map.movieTicket")}</h4>{" "}
                          <span>
                            {cityInfo.movieTicket.price}
                            {cityInfo.currency.symbol}
                          </span>
                          <Rate
                            allowHalf
                            style={{
                              color:
                                cityInfo.movieTicket.index > 0.7
                                  ? "green"
                                  : cityInfo.movieTicket.index > 0.3
                                  ? "orange"
                                  : "red"
                            }}
                            disabled
                            defaultValue={5 * (1 - cityInfo.movieTicket.index)}
                            character={<AttachMoney />}
                          />
                        </CostOfLivingContainer>
                      </StepsContainer>
                    ) : (
                      <StepsContainer>{t("map.noResultsCost")}</StepsContainer>
                    ))}
                </div>
              </div>
            )}
            <br />
            <Separator />
            <div>
              <TitleText className="hover-underline-animation">
                {t("map.tripSectionItinerary")}
              </TitleText>
              {Array.from(
                {
                  length: mapGjsonToSteps(
                    currentTrip,
                    !!hotel.length,
                    transportMode,
                    mapRef,
                    setItineraryDay
                  ).length
                },
                (v, i) => i
              ).map((day, index) => {
                const handleOpenDays = () => {
                  const temp = [...openDays];
                  temp[index] = !temp[index];
                  setOpenDays(temp);
                };

                return (
                  // eslint-disable-next-line
                  <div key={day} ref={(el) => (daysRefs.current[index] = el!)}>
                    <SubtitleText onClick={handleOpenDays}>
                      <FontAwesomeIcon
                        width={25}
                        icon={openDays[index] ? faChevronDown : faChevronRight}
                      />
                      <p>
                        {format(
                          addDays(range[0].startDate || new Date(), day),
                          "EEEE, do MMMM"
                        )}
                      </p>
                    </SubtitleText>
                    {openDays[index] && (
                      <StepsContainer>
                        <Steps
                          items={
                            mapGjsonToSteps(
                              currentTrip,
                              !!hotel.length,
                              transportMode,
                              mapRef,
                              setItineraryDay
                            )[day]
                          }
                          direction="vertical"
                          current={10000}
                        />
                      </StepsContainer>
                    )}
                  </div>
                );
              })}
            </div>
            <Separator />
            <div>
              <TitleText className="hover-underline-animation" ref={budgetRef}>
                {t("map.tripSectionBudget")}
              </TitleText>
              <BudgetComponent
                cityName={tripData.cityName || ""}
                lat={tripData.lat || 0}
                lon={tripData.lon || 0}
                tripLength={
                  mapGjsonToSteps(
                    currentTrip,
                    !!hotel.length,
                    transportMode,
                    mapRef,
                    setItineraryDay
                  ).length
                }
              />
            </div>
          </ContentContainer>
        </InformationContainer>
      </DetailsContainer>
      <MapContainer isTablet={isTablet}>
        <div className="mapWrapper" id="mapWrapper">
          <div
            id="mapContainer"
            className={
              "map" +
              (isHotelSelectionActivated ? " mapContainerHotelSelectionOn" : "")
            }
          >
            {generateItineraryStatus.loading && tripData.cityName && (
              <LoadingItineraryContainer isTablet={isTablet}>
                <p>
                  <b>
                    {t("map.generateLoadingPart1")}{" "}
                    {tripData.cityName.toUpperCase()}{" "}
                    {t("map.generateLoadingPart2")}
                  </b>
                </p>
                <span className="loader"></span>
              </LoadingItineraryContainer>
            )}
            <Map
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              initialViewState={{
                latitude: tripData.lat as number,
                longitude: tripData.lon as number,
                zoom: 10
              }}
              ref={mapRef}
              cursor={cursor}
              mapStyle="mapbox://styles/mapbox/streets-v12"
              projection={"globe"}
              interactiveLayerIds={[
                "city-layer",
                ...Object.keys(routes)
                  .map((key) => parseInt(key))
                  .map((key) => `routeline-active${key}`)
              ]}
              onMouseEnter={() => setCursor("pointer")}
              onMouseLeave={() => setCursor("grab")}
              onClick={handleMapClick}
              onDblClick={handleMapDblClick}
              minZoom={1}
            >
              <GeolocateControl
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
              />
              <NavigationControl />
              <ScaleControl maxWidth={100} unit="metric" />
              <Source
                id="cities"
                type="geojson"
                data={`${process.env.REACT_APP_URI}/data.geojson`}
              />
              <Layer
                id="city-layer"
                type="circle"
                source="cities"
                paint={{
                  "circle-radius": 4,
                  "circle-stroke-width": 1,
                  "circle-color": "red",
                  "circle-stroke-color": "white"
                }}
              />
              {markers?.map((marker) => marker)}
              {hotel?.map((marker) => marker)}
              <Routes
                routes={routes}
                colors={weekColors}
                itineraryDay={itineraryDay}
              />
            </Map>
          </div>
        </div>
      </MapContainer>
      {isTripSaveModalOpen && (
        <div className="tripSaveModal">
          <div className="tripSaveModalContent">
            <h2>{t("map.tripSaveModalContentTitle")}</h2>
            <p>{t("map.tripSaveModalContentDescription")}</p>
            {saveTripStatus.loading ? <CircularProgress /> : null}
            <div className="tripSaveModalButtons">
              <button
                type="button"
                className="tripSaveModalButton"
                onClick={() => toggleIsTripSaveModalOpen(false)}
                disabled={saveTripStatus.loading}
              >
                {t("general.cancel")}
              </button>
              <button
                type="button"
                disabled={saveTripStatus.loading}
                className="tripSaveModalButton"
                onClick={async () => {
                  await saveTrip({
                    startDate: range[0]?.startDate?.getTime() || 0,
                    endDate: range[0]?.endDate?.getTime() || 0,
                    housingCoordinates: [
                      hotel[0]?.props.longitude || 0,
                      hotel[0]?.props.latitude || 0
                    ],
                    cityName: tripData.cityName || "",
                    tripData: currentTrip as GeoJsonRes
                  });
                  toggleIsTripSaveModalOpen(false);
                }}
              >
                {t("general.save")}
              </button>
            </div>
          </div>
        </div>
      )}
      {isTripLoadModalOpen && (
        <div className="tripSaveModal">
          <div className="tripSaveModalContent">
            <h2>{t("map.tripLoadModalContentTitle")}</h2>
            {getTripsStatus.loading ? (
              <CircularProgress />
            ) : (
              <div>
                <Table
                  size="small"
                  dataSource={trips.map((trip) => {
                    const endDate = new Date(0);
                    const startDate = new Date(0);
                    endDate.setUTCSeconds(trip.endDate / 1000);
                    startDate.setUTCSeconds(trip.startDate / 1000);
                    return {
                      ...trip,
                      endDate: format(endDate, "dd MMM yyyy"),
                      startDate: format(startDate, "dd MMM yyyy")
                    };
                  })}
                  columns={tripsColumns}
                  bordered
                />
              </div>
            )}
            <div className="tripSaveModalButtons">
              <button
                type="button"
                className="tripSaveModalButton"
                onClick={() => toggleIsTripLoadModalOpen(false)}
                disabled={saveTripStatus.loading}
              >
                {t("general.cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div<PageContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isTablet ? "column-reverse" : "row")};
`;

const LoadingContainer = styled.div<PageContainerProps>`
  width: ${(props) => (props.isTablet ? "100%" : "50%")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  min-height: calc(100vh - 50px);
  height: ${(props) => (props.isTablet ? "80vh" : "100vh")};
  background-color: ${COLORS.light};
  z-index: 80;
`;

const LoadingItineraryContainer = styled.div<PageContainerProps>`
  width: 100%;
  height: ${(props) => (props.isTablet ? "80vh" : "100vh")};
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  color: white;
  margin: auto;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
`;

const DetailsContainer = styled.div<PageContainerProps>`
  width: ${(props) => (props.isTablet ? "100%" : "50%")};
  display: flex;
  flex-direction: row;
  height: ${(props) => (props.isTablet ? "fit-content" : "calc(100vh - 50px)")};
  box-shadow: ${(props) =>
    props.isTablet
      ? "15px 0 40px rgba(0, 0, 0, 0.65)"
      : "0 15px 40px rgba(0, 0, 0, 0.65)"};
  padding-top: ${(props) => (props.isTablet ? "0" : "50px")};
  z-index: 50;
`;

const TimeLineContainer = styled.div`
  width: 20%;
  padding-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.12);

  @media screen and (max-width: 1025px) {
    display: none;
  }
  z-index: 30;
`;

const OverviewButton = styled.div`
  width: 120px;
  height: 48px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: ${COLORS.blue};
  color: ${COLORS.light};
  font-weight: 700;
  font-size: 18px;
  font: ${FONT};

  &:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  }
`;

const TimeLineTextContainer = styled.div`
  width: calc(100% - 50px);
  padding-left: 50px;
  transition: all ease-in-out 0.2s;
`;

const TimeLineButton = styled.div`
  width: 120px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  color: ${COLORS.dark};
  font-weight: 700;
  font-size: 16px;
  font: ${FONT};

  &:hover {
    color: ${COLORS.grey};
  }
`;

const TimeLineText = styled.p`
  color: ${COLORS.dark};
  font-weight: 700;
  font-size: 14px;
  font: ${FONT};

  &:hover {
    color: ${COLORS.grey};
  }
`;

const InformationContainer = styled.div<PageContainerProps>`
  width: ${(props) => (props.isTablet ? "100%" : "80%")};
  height: ${(props) => (props.isTablet ? "fit-content" : "calc(100vh - 50px)")};
  display: flex;
  flex-direction: column;
`;

const ResearchBarContainer = styled.div`
  width: calc(100% - 20px);
  padding: 0 10px;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);

  @media (max-width: 425px) {
    flex-direction: column;
    height: auto;
    width: auto;
    gap: 10px;
    margin: 10px 0;
  }
`;

const ContainerComponent = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const SaveTripButton = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${COLORS.white};
  border-width: 1px;
  border-style: solid;
  border-color: #cecece;
  cursor: pointer;

  &:hover {
    border-color: gray;
    transition: all ease-in-out 0.14s;
  }
`;

const FiltersContainer = styled.div`
  padding-left: 32px;
  width: calc(100% - 32px);
  height: 5vh;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const ContentContainer = styled.div<PageContainerProps>`
  width: calc(100% - 32px);
  gap: 15px;
  padding-left: 32px;
  max-height: ${(props) =>
    props.isTablet ? "fit-content" : "calc(80vh - 10px)"};
  overflow: ${(props) => (props.isTablet ? "none" : "scroll")};

  @media (max-width: 768px) {
    padding-left: 24px;
  }
  @media (max-width: 425px) {
    padding-left: 16px;
  }
`;

const MapContainer = styled.div<PageContainerProps>`
  width: ${(props) => (props.isTablet ? "100%" : "50%")};
  height: ${(props) => (props.isTablet ? "80vh" : "100vh")};
`;

const HotelMarker = styled.img`
  z-index: 999;
  position: relative;
`;

const LogoWrapper = styled.div`
  @keyframes bubble {
    0% {
      transform: scale(0.9);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.9);
    }
  }
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all ease-in-out 0.2s;
  animation: bubble 1s infinite;
`;

const PictureWrapper = styled.img`
  height: 200px;

  &:hover {
    color: ${COLORS.links};
  }
`;

const TravelText = styled.div`
  margin: 20px 0;
  color: ${COLORS.dark};
  font-weight: 700;
  font-size: 28px;
  font: ${FONT};
`;

const TitleText = styled.div`
  margin: 18px 0;
  color: ${COLORS.dark};
  font-weight: 700;
  font-size: 22px;
  font: ${FONT};
`;

const SubtitleText = styled.div`
  margin: 16px 0;
  color: ${COLORS.dark};
  font-weight: 600;
  font-size: 20px;
  font: ${FONT};
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 10px;

  &:hover {
  }
`;

const StepsContainer = styled.div`
  width: calc(100% - 30px);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
`;

const CostOfLivingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const Separator = styled(Divider)`
  margin: 10px 0 !important;
  background-color: lightgray;
`;

export default NewBaseMap;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";

const LogoutButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.removeItem("jwt");
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  return (
    <Button
      danger
      onClick={() => {
        logoutUser();
      }}
    >
      {t("profile.logout")}
    </Button>
  );
};

export default LogoutButton;

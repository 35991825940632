import React, { FC, useState } from "react";
import { Grid } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { COLORS } from "../../UI/Colors";
import AddIcon from "@mui/icons-material/Add";

import BrowseEvent from "./BrowseEvent";
import CreateEvent from "./CreateEvent";

import WithHeader from "../../Layout/WithHeader";
import { useSelector } from "react-redux";
import { getUserToken } from "../../reducers/auth.reducers";

enum TAB {
  CREATE,
  BROWSE,
  MYEVENT
}

const EventPage: FC = () => {
  const [tab, setTab] = useState<TAB>(TAB.BROWSE);
  const { t } = useTranslation();
  const isLoggedIn = useSelector(getUserToken);
  const [openCreateEvent, setOpenCreateEvent] = useState<boolean>(false);

  const handleOpenCreateEventModal = () => {
    setOpenCreateEvent(true);
  };

  const handleCloseCreateEventModal = () => {
    setOpenCreateEvent(false);
  };

  return (
    <div>
      <EventWrapper container p={0} m={0} rowGap={10}>
        <Grid item p={0} m={0} xs={12}>
          {isLoggedIn ? <WithHeader /> : <Navbar />}
        </Grid>
      </EventWrapper>
      <Tabs>
        <TabButton onClick={() => setTab(TAB.BROWSE)}>
          <Typography
            variant="h6"
            fontWeight={"bolder"}
            color={tab === TAB.BROWSE ? "black" : "darkgray"}
          >
            {t("event.browse")}
          </Typography>
        </TabButton>
        <CreateButton onClick={handleOpenCreateEventModal}>
          <AddIcon />
        </CreateButton>
      </Tabs>
      <CreateEvent
        isModalOpen={openCreateEvent}
        handleModalClose={handleCloseCreateEventModal}
      />
      {tab === TAB.BROWSE ? <BrowseEvent /> : null}
      {/* {tab === TAB.MYEVENT ? "Might swap to event detail ?" : null} */}
    </div>
  );
};

const EventWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Tabs = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 40px;
`;

const TabButton = styled.div`
  padding: 10px;
`;

const CreateButton = styled.button`
  width: 54px;
  height: 48px;
  border: none;
  border-radius: 10px;
  background-color: ${COLORS.blue};
  color: ${COLORS.white};
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: ${COLORS.blue};
    scale: 1.02;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export default EventPage;

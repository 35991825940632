import React from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  DialogContent,
  DialogActions
} from "@mui/material";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "./DeleteAccountModal.utils";

import styled from "styled-components";
import { COLORS, FONT } from "../../../UI/Colors";
import { Close } from "@mui/icons-material";

type DeleteAccountModalProps = {
  setShowDelete: (value: boolean) => void;
  showDelete: boolean;
};

const DeleteAccountModal = ({
  setShowDelete,
  showDelete
}: DeleteAccountModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const DeleteAccount = async () => {
    try {
      await deleteUser(localStorage.getItem("jwt") || "");
      localStorage.clear();
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showDelete}
        onClose={() => setShowDelete(false)}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle>
          <b>{t("profile.deleteAccount")}</b>
          <Divider style={{ margin: "10px 0" }} />
          <Box
            component="span"
            sx={{ position: "absolute", top: 0, right: 0, m: 1 }}
          >
            <IconButton
              color="inherit"
              onClick={() => setShowDelete(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <p>
            {t("profile.textDelete")}
            <br /> {t("profile.textDeletepart2")}
          </p>
        </DialogContent>
        <DialogActions style={{ marginTop: "16px" }}>
          <CancelButton onClick={() => setShowDelete(false)}>
            {t("general.cancel")}
          </CancelButton>
          <DeleteAccountButton onClick={() => DeleteAccount()}>
            {t("general.delete")}
          </DeleteAccountButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CancelButton = styled.button`
  background-color: transparent;
  color: ${COLORS.text}
  padding: 10px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: ${COLORS.blue};
  }
`;

const DeleteAccountButton = styled.button`
  background-color: crimson;
  color: ${COLORS.bg};
  padding 10px 20px;
  border-radius: 5px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background-color: red;
    transition: all ease-in-out 0.2s;
  }
`;

export default DeleteAccountModal;

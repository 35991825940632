import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import { RootState } from "../store";
import WithHeader from "../Layout/WithHeader";
import styled from "styled-components";
import { useFetchUser } from "../hooks/useFetchUser";
import { CircularProgress } from "@mui/material";

type ProtectedProps = {
  isMap?: boolean;
  children: JSX.Element;
};

const Protected: FC<ProtectedProps> = ({ children, isMap = false }) => {
  const [loading, setLoading] = useState(true);
  let isLoggedIn = useSelector<RootState, boolean>(
    (state) => state.auth.isLoggedIn
  );
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const [, fetchUser] = useFetchUser();

  const initialize = async () => {
    if (isLoggedIn) {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch({ type: "LOGIN", payload: {} });
        isLoggedIn = false;
      } else {
        dispatch({ type: "LOGIN", payload: token });
        await fetchUser();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, [isLoggedIn, dispatch, fetchUser]);

  if (loading) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  if (isLoggedIn) {
    return (
      <PageContainer>
        <WithHeader half={isMap} />
        {children}
      </PageContainer>
    );
  }

  return <Navigate to="/login" replace />;
};

const PageContainer = styled.div`
  width: 100%;
  height: fit-content;
`;

export default Protected;

import React, { useState, FC } from "react";
import {
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Grid,
  Box
} from "@mui/material";
import {
  ExpandMore,
  Close,
  Delete,
  WineBar,
  Hotel,
  LocalGroceryStore,
  Attractions,
  Restaurant,
  DirectionsBus,
  LocalGasStation,
  Receipt,
  Flight,
  ShoppingBag,
  TipsAndUpdates
} from "@mui/icons-material";
import styled from "styled-components";
import { COLORS, FONT } from "../../UI/Colors";
import { Button, Divider, Modal, Table } from "antd";
import { format } from "date-fns";
import { useFetchCityInfo } from "../../hooks/useFetchCityInfo";
import { useTranslation } from "react-i18next";

interface Expense {
  id: number;
  category: string;
  amount: string;
  commentary: string;
}

type BudgetComponentProps = {
  tripLength: number;
  cityName: string;
  lat: number;
  lon: number;
};

const BudgetComponent: FC<BudgetComponentProps> = ({
  tripLength,
  cityName,
  lat,
  lon
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [amount, setAmount] = useState("0");
  const [commentary, setCommentary] = useState("");
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [isAutoBudgetModalOpen, setIsAutoBudgetModalOpen] = useState(false);

  const [cityInfosStatus, fetchCityInfo] = useFetchCityInfo();
  const { t } = useTranslation();
  const categoryIcons: Record<string, React.ReactNode> = {
    [t("budget.categoryIconsNameFlights")]: <Flight />,
    [t("budget.categoryIconsNameFood")]: <Restaurant />,
    [t("budget.categoryIconsNameTransport")]: <DirectionsBus />,
    [t("budget.categoryIconsNameActivities")]: <Attractions />,
    [t("budget.categoryIconsNameDrinks")]: <WineBar />,
    [t("budget.categoryIconsNameLodging")]: <Hotel />,
    [t("budget.categoryIconsNameShopping")]: <ShoppingBag />,
    [t("budget.categoryIconsNameGroceries")]: <LocalGroceryStore />,
    [t("budget.categoryIconsNameGas")]: <LocalGasStation />,
    [t("budget.categoryIconsNameOthers")]: <Receipt />
  };

  const handleAddExpense = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedCategory("");
    setAmount("0");
    setCommentary("");
  };

  const handleSaveExpense = () => {
    const newExpense: Expense = {
      id: Date.now(),
      category: selectedCategory,
      amount,
      commentary
    };

    setExpenses([...expenses, newExpense]);
    handleModalClose();
  };

  const handleDeleteExpense = (id: number) => {
    setExpenses((prevExpenses) =>
      prevExpenses.filter((expense) => expense.id !== id)
    );
  };

  const totalBudget = expenses.reduce(
    (total, expense) => total + parseFloat(expense.amount),
    0
  );

  const handleOk = async () => {
    const cityInfos = await fetchCityInfo({ cityName, lat, lon });
    if (!cityInfos) return;
    setExpenses([
      {
        id: Date.now() + Math.random() * 1000,
        category: "Lodging",
        amount: (
          cityInfos.hotelNight.price *
          (tripLength - 1) *
          cityInfos.currency.overUSD
        ).toFixed(2),
        commentary: `${tripLength - 1} hotel nights`
      },
      {
        id: Date.now() + Math.random() * 1001,
        category: "Food",
        amount: (
          cityInfos.lunch.price *
          2 *
          tripLength *
          cityInfos.currency.overUSD
        ).toFixed(2),
        commentary: `2 meals for ${tripLength} days`
      },
      {
        id: Date.now() + Math.random() * 1002,
        category: "Drinks",
        amount: (
          cityInfos.drinks.price *
          tripLength *
          cityInfos.currency.overUSD
        ).toFixed(2),
        commentary: `${tripLength} drinks`
      },
      {
        id: Date.now() + Math.random() * 1003,
        category: "Transport",
        amount: (
          cityInfos.transportTicket.price *
          tripLength *
          6 *
          cityInfos.currency.overUSD
        ).toFixed(2),
        commentary: `Transport ticket for each day and each commute`
      },
      {
        id: Date.now() + Math.random() * 1004,
        category: "Activities",
        amount: (
          cityInfos.movieTicket.price *
          tripLength *
          0.2 *
          cityInfos.currency.overUSD
        ).toFixed(2),
        commentary: `Estimated price for activities`
      }
    ]);
    setIsAutoBudgetModalOpen(false);
  };

  return (
    <>
      <Modal
        confirmLoading={cityInfosStatus.loading}
        title={t("autobudget.modalTitle")}
        open={isAutoBudgetModalOpen}
        onOk={handleOk}
        onCancel={() => setIsAutoBudgetModalOpen(false)}
      >
        <p>{t("autobudget.areyousure")}</p>
      </Modal>
      <CardContainer>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "14px"
          }}
        >
          <AutoButton
            icon={<TipsAndUpdates />}
            onClick={() => setIsAutoBudgetModalOpen(true)}
            shape="circle"
            type="primary"
            size="large"
          ></AutoButton>
          <Typography variant="h5" style={{}}>
            {totalBudget.toFixed(2)}$
          </Typography>
          <div
            style={{
              width: "1px",
              height: "52px",
              marginLeft: "16px",
              marginRight: "16px",
              backgroundColor: "#BBBBBB"
            }}
          />
          <AddExpenselButton onClick={handleAddExpense}>
            {t("budget.addExpenses")}
          </AddExpenselButton>
        </CardContent>
      </CardContainer>

      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle>
          {t("budget.titleModal")}
          <Divider style={{ margin: "10px 0" }} />
          <Box
            component="span"
            sx={{ position: "absolute", top: 0, right: 0, m: 1 }}
          >
            <IconButton
              color="inherit"
              onClick={handleModalClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <p>{t("budget.selectCategoryTextModal")}</p>
          <Grid
            container
            spacing={2}
            style={{ marginLeft: "0", marginBottom: "16px" }}
          >
            {Object.entries(categoryIcons).map(([key, icon]) => (
              <Grid item key={key}>
                <CategoryButton
                  color={selectedCategory === key ? "primary" : "default"}
                  onClick={() => setSelectedCategory(key)}
                >
                  <div style={{ borderRadius: "5px", overflow: "hidden" }}>
                    {icon}
                  </div>
                  <Typography variant="caption">{key}</Typography>
                </CategoryButton>
              </Grid>
            ))}
          </Grid>
          <TextField
            fullWidth
            label={t("budget.amount")}
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value.replace(",", "."))}
            disabled={!selectedCategory}
            style={{ marginTop: "16px", borderRadius: "5px" }}
          />
          <TextField
            fullWidth
            label={t("budget.addDescription")}
            value={commentary}
            onChange={(e) => setCommentary(e.target.value)}
            disabled={!selectedCategory}
            style={{ marginTop: "16px", borderRadius: "5px" }}
          />
        </DialogContent>
        <DialogActions style={{ marginTop: "16px" }}>
          <CancelButton onClick={handleModalClose}>
            {t("general.cancel")}
          </CancelButton>
          <SaveExpenseButton
            onClick={handleSaveExpense}
            disabled={!selectedCategory}
          >
            {t("general.save")}
          </SaveExpenseButton>
        </DialogActions>
      </Dialog>

      <Accordion
        style={{
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          width: `calc(100% - 36px)`,
          margin: "0",
          marginLeft: "20px !important"
        }}
        square={true}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{ flexDirection: "row-reverse" }}
        >
          <SubtitleText>{t("budget.expenses")}</SubtitleText>
        </AccordionSummary>
        <AccordionDetails>
          {expenses.length === 0 ? (
            <Typography style={{ color: "gray" }}>
              {t("budget.textNoExpenses")}
            </Typography>
          ) : (
            <Table
              dataSource={expenses}
              columns={[
                {
                  title: t("budget.category"),
                  dataIndex: "category",
                  key: "category",
                  sorter: (a, b) => a.category.localeCompare(b.category)
                },
                {
                  title: t("budget.description"),
                  dataIndex: "commentary",
                  key: "commentary"
                },
                {
                  title: t("budget.amount"),
                  dataIndex: "amount",
                  key: "amount",
                  sorter: (a, b) => (a.amount < b.amount ? -1 : 1)
                },
                {
                  title: t("budget.createdDate"),
                  dataIndex: "id",
                  key: "id",
                  sorter: (a, b) => (a.id < b.id ? -1 : 1),
                  render: function (time, record) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>
                          {format(new Date(time), "HH:mm - dd/LL/yyyy")}
                        </span>
                        <IconButton
                          onClick={() => handleDeleteExpense(record.id)}
                          color="inherit"
                          aria-label="delete"
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    );
                  }
                }
              ]}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const CategoryButton = styled(IconButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  height: 70px;
  border-radius: 5px !important;
  background-color: #eeeeee !important;

  &:hover {
    background-color: lightgray !important;
  }
`;

const AddExpenselButton = styled.button`
  background-color: ${COLORS.blue};
  color: ${COLORS.bg};

  padding: 12px 20px;
  border-radius: 5px;
  font-family: ${FONT};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background-color: ${COLORS.blueGreen};
    transition: all ease-in-out 0.2s;
  }
`;

const SaveExpenseButton = styled.button`
  background-color: ${COLORS.blue};
  color: ${COLORS.bg};
  padding: 10px 20px;
  border-radius: 5px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:disabled {
    background-color: lightgray;
  }

  &:hover:enabled {
    background-color: ${COLORS.blueGreen};
    transition: all ease-in-out 0.2s;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  background-color: transparent;
  color: ${COLORS.text}
  padding: 10px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: ${COLORS.blue};
  }
`;

const AutoButton = styled(Button)`
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: ${COLORS.blue} !important;

  &:hover {
    background-color: ${COLORS.blueGreen} !important;
    scale: 1.06;
  }
`;

const CardContainer = styled(Card)`
  margin: 20px 0 16px 20px;
  width: fit-content;
  border-radius: 5px;
  background-color: #dfe0e4 !important;
  padding: 8px 16px;
  position: relative;
  overflow: visible !important;

  @media (max-width: 768px) {
    margin: 0 0 0 0;
    background-color: red;
    padding: 4px 12px;
  }
`;

const SubtitleText = styled.div`
  color: ${COLORS.dark};
  font-weight: 600;
  font-size: 20px;
  font: ${FONT};
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 10px;

  &:hover {
  }
`;

export default BudgetComponent;

import {
  Chip,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper
} from "@mui/material";
import { Popper } from "@mui/base";
import { TransportType } from "../Maps.type";
import {
  DirectionsBike,
  DirectionsCar,
  DirectionsWalk
} from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type TransportFilterProps = {
  transportMode: TransportType;
  setTransportMode: React.Dispatch<React.SetStateAction<TransportType>>;
};

const TransportFilter = ({
  transportMode,
  setTransportMode
}: TransportFilterProps) => {
  const [openTransport, setOpenTransport] = useState(false);
  const transportRef = useRef(null);
  const { t } = useTranslation();

  return (
    <>
      <Chip
        label={t("transports.title")}
        onClick={() => setOpenTransport((prevState) => !prevState)}
        ref={transportRef}
      />
      <Popper
        open={openTransport}
        anchorEl={transportRef.current}
        placement="bottom-start"
        disablePortal
        style={{ zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={() => setOpenTransport(false)}>
          <Paper>
            <List>
              <ListItem>
                <ListItemButton
                  selected={transportMode === TransportType.DRIVING}
                  onClick={() => {
                    setTransportMode(TransportType.DRIVING);
                    setOpenTransport(false);
                  }}
                >
                  <ListItemIcon>
                    <DirectionsCar />
                  </ListItemIcon>
                  <ListItemText primary={t("transports.car")} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={transportMode === TransportType.CYCLING}
                  onClick={() => {
                    setTransportMode(TransportType.CYCLING);
                    setOpenTransport(false);
                  }}
                >
                  <ListItemIcon>
                    <DirectionsBike />
                  </ListItemIcon>
                  <ListItemText primary={t("transports.bike")} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  selected={transportMode === TransportType.WALKING}
                  onClick={() => {
                    setTransportMode(TransportType.WALKING);
                    setOpenTransport(false);
                  }}
                >
                  <ListItemIcon>
                    <DirectionsWalk />
                  </ListItemIcon>
                  <ListItemText primary={t("transports.walk")} />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default TransportFilter;

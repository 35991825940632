import { useWebClient } from "./useWebClient";
import { useTypedAsyncFn } from "./useTypedAsyncFn";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export const useAddFriends = () => {
  const webClient = useWebClient();
  const { t } = useTranslation();

  return useTypedAsyncFn(async (id) => {
    const promise = webClient.post(`user/friend-request/${id}`);
    toast.promise(promise, {
      success: t("friends.successAdd"),
      error: t("friends.errorAdd"),
      loading: t("friends.loadingAdd")
    });
    await promise;
  }, []);
};

import React, { FC } from "react";
import Drawer from "@mui/material/Drawer";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import styled from "styled-components";
import { COLORS, FONT } from "../../UI/Colors";
import { CookieSharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type CookiesProps = {
  open: boolean;
  //eslint-disable-next-line
  onClose: any;
};

const Cookies: FC<CookiesProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Drawer
        variant="permanent"
        anchor="bottom"
        open={open}
        onClose={() => onClose()}
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <CookieSharp />
            </ListItemIcon>
            <ListItemText
              primary={t("cookies.primary")}
              secondary={t("cookies.secondary")}
            />
            <AcceptButton onClick={() => onClose()}>
              {t("general.skip")}
            </AcceptButton>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

const AcceptButton = styled.button`
  color: ${COLORS.bg};
  background-color: ${COLORS.blue};
  font-family: ${FONT};
  cursor: pointer;
  border: none;
  width: 140px;
  height: 48px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  margin: 10px 10px 10px 50px;

  &:hover {
    background-color: ${COLORS.blueGreen};
    color: ${COLORS.bg};
    transition-duration: 0.4s;
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 40px;
    font-size: 15px;
    margin: 10px 0px 10px 10px;
  }
`;

export default Cookies;

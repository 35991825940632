import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@mui/material";
import { Card, CardContent, CardActions } from "@mui/material";
import { Button, Divider } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import { COLORS, FONT } from "../../../UI/Colors";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getUser } from "../../../reducers/auth.reducers";

import { useAddFriends } from "../../../hooks/useAddFriends";
import { useRefuseFriends } from "../../../hooks/useRefuseFriends";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { Close } from "@mui/icons-material";

const FriendsGrid: React.FC = () => {
  const [, addFriends] = useAddFriends();
  const [, refuseFriend] = useRefuseFriends();
  const [, fetchUserInfo] = useFetchUser();
  const user = useSelector(getUser);
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState<string | null>(null);

  const handleOk = () => {
    setIsModalVisible(true);
    setSelectedFriend(null);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedFriend(null);
  };

  const newFollowers = user
    ? user.followers.filter(
        (follower) =>
          !user.following.some((following) => following.id === follower.id)
      )
    : [];

  const realFriends = user
    ? user.followers.filter((follower) =>
        user.following.some((following) => follower.id === following.id)
      )
    : [];

  const addFriendsFn = async (id: string) => {
    console.log("caca");
    await addFriends(id);
    await fetchUserInfo();
  };

  const refuseFriendsFn = async (id: string) => {
    await refuseFriend(id);
    await fetchUserInfo();
  };

  return (
    <>
      {user && (
        <ContainerFriends>
          <Box>
            <Card sx={{ width: 380 }}>
              {realFriends.length > 0 ? (
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <Typography variant="h6">
                      {t("friends.fabulousFriends")}
                    </Typography>
                    <Button
                      onClick={handleOk}
                      style={{
                        alignItems: "center",
                        backgroundColor: "#1464C0",
                        color: "#F3F4F8",
                        border: "none",
                        padding: "5px 5px",
                        cursor: "pointer"
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                  <Divider />
                  <List>
                    {realFriends.map((friend) => (
                      <ListItem key={friend.id}>
                        <ListItemAvatar>
                          <Avatar
                            alt={friend.username}
                            src={`https://api.dicebear.com/8.x/notionists-neutral/svg?seed=${localStorage.getItem(
                              friend.email
                            )}`}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={friend.username} />
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => refuseFriendsFn(friend.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              ) : (
                <CardContent>
                  <Typography variant="h6">
                    {t("friends.fabulousFriends")}
                  </Typography>
                  <Divider />
                  <div
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <h4>{t("friends.noFriends")}</h4>
                    <Button
                      onClick={handleOk}
                      style={{
                        alignItems: "center",
                        backgroundColor: "#1464C0",
                        color: "#F3F4F8",
                        border: "none",
                        padding: "5px 10px",
                        cursor: "pointer"
                      }}
                    >
                      {t("friends.add")}
                    </Button>
                  </div>
                </CardContent>
              )}
            </Card>
          </Box>
          <Dialog
            open={isModalVisible}
            onClose={handleCancel}
            PaperProps={{ sx: { borderRadius: "10px" } }}
          >
            <DialogTitle>
              {t("friends.add")}
              <Divider style={{ margin: "10px 0" }} />
              <Box
                component="span"
                sx={{ position: "absolute", top: 0, right: 0, m: 1 }}
              >
                <IconButton
                  color="inherit"
                  onClick={handleCancel}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
              <TextField
                fullWidth
                required
                label={t("friends.id")}
                onChange={(e) => setSelectedFriend(e.target.value)}
                style={{ marginTop: "16px", borderRadius: "5px" }}
              />
            </DialogContent>
            <DialogActions>
              <CancelButton onClick={handleCancel}>
                {t("general.cancel")}
              </CancelButton>
              <SendRequestButton
                onClick={() => addFriendsFn(selectedFriend as string)}
              >
                {t("general.send")}
              </SendRequestButton>
            </DialogActions>
          </Dialog>

          <Box>
            <Card>
              <CardContent>
                <Typography variant="h6">{t("friends.pending")}</Typography>
                <Divider />
                {newFollowers.length > 0 ? (
                  <Grid container>
                    {newFollowers.map((request) => (
                      <Grid item key={request.id} xs={12} sm={6} md={4}>
                        <Card
                          sx={{
                            width: "fit-content",
                            margin: "20px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            transition: "transform 0.3s",
                            "&:hover": {
                              transform: "scale(1.05)"
                            }
                          }}
                        >
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center"
                            }}
                          >
                            <Avatar
                              alt={request.username}
                              src={`https://api.dicebear.com/8.x/notionists-neutral/svg?seed=${localStorage.getItem(
                                request.email
                              )}`}
                            />
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              {request.username}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button
                              type="primary"
                              onClick={() => addFriendsFn(request.id)}
                            >
                              {t("general.confirm")}
                            </Button>
                            <Button
                              type="default"
                              danger
                              onClick={() =>
                                refuseFriendsFn(selectedFriend as string)
                              }
                            >
                              {t("general.delete")}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <div>{t("friends.noRequest")}</div>
                )}
              </CardContent>
            </Card>
          </Box>
        </ContainerFriends>
      )}
    </>
  );
};

const ContainerFriends = styled.div`
  padding: 0 100px;
  display: flex;
  flex-direction: row;
  gap: 50px;
`;

const CancelButton = styled.button`
  background-color: transparent;
  color: ${COLORS.text}
  padding: 10px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: ${COLORS.blue};
  }
`;

const SendRequestButton = styled.button`
  background-color: ${COLORS.blue};
  color: ${COLORS.bg};
  padding 10px 20px;
  border-radius: 5px;
  font-family: ${FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  &:disabled {
    background-color: lightgray;
    cursor: block;
  }

  &:hover:enabled {
    background-color: ${COLORS.blueGreen};
    transition: all ease-in-out 0.2s;
  }

  &:hover:disabled {
    cursor: not-allowed;
  }
`;

export default FriendsGrid;

import React from "react";
import "./WithHeaders.scss";
import { useTranslation } from "react-i18next";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import { LanguageSwitch } from "../components/LanguageSwitch/LanguageSwitch";
import styled from "styled-components";
import { COLORS, FONT } from "../UI/Colors";
import DrawerComponent from "../components/Navbar/DrawerComponent";
import simpleLogo from "../assets/Trotter_logo.png";
import { ReportComponent } from "../components/ReportComponent/ReportComponent";
import { getUser } from "../reducers/auth.reducers";
import { useSelector } from "react-redux";

type WithHeaderProps = {
  half?: boolean;
  isLoggedIn?: boolean;
};

type NavButtons = {
  key: string;
  redirect: string;
}[];

const navButtons: NavButtons = [
  {
    key: "home",
    redirect: "/"
  },
  {
    key: "discover",
    redirect: "/map"
  },
  {
    key: "trending",
    redirect: "/trending"
  }
];

const WithHeader = ({ half = false, isLoggedIn = true }: WithHeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const user = useSelector(getUser);

  return (
    <HeaderContainer
      half={!useMediaQuery(theme.breakpoints.down("lg")) && half}
      title="header"
    >
      <LeftWrapper>
        <LogoWrapper>
          <a href={"/"}>
            <PictureWrapper src={simpleLogo} alt={"Logo"} />
          </a>
          {!isMobile && <LogoLink to={"/"}>Trotter</LogoLink>}
        </LogoWrapper>
        {!isMobile && (
          <div className="navButtonsContainer">
            {navButtons.map((button) => (
              <button
                type="button"
                key={button.key}
                onClick={() => navigate(button.redirect)}
              >
                <Typography variant="button">
                  {t(button.key).toUpperCase()}
                </Typography>
              </button>
            ))}
          </div>
        )}
      </LeftWrapper>
      <EndNavbarWrapper>
        <LanguageSwitch />
        {localStorage.getItem("jwt") ? (
          <Link to="/profile">
            <Avatar
              src={`https://api.dicebear.com/8.x/notionists-neutral/svg?seed=${localStorage.getItem(
                user ? user.email : "jwt"
              )}`}
            />
          </Link>
        ) : null}
        {/* <Link to="/profile">
          <Avatar
            src={`https://api.dicebear.com/8.x/notionists-neutral/svg?seed=${localStorage.getItem(
              user ? user.email : "jwt"
            )}`}
          />
        </Link> */}
        {isMobile ? (
          <DrawerComponent />
        ) : isLoggedIn ? (
          <div className="profileButtonContainer">
            <ReportComponent />
          </div>
        ) : (
          <>
            <LoginLink to={"/login"}>{t("general.logIn")}</LoginLink>
            <RegisterLink to={"/register"}>
              {t("general.register")}
            </RegisterLink>
          </>
        )}
      </EndNavbarWrapper>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.head<WithHeaderProps>`
  width: ${(props) => (props.half ? "calc(50% - 40px)" : "calc(100% - 40px)")};
  height: 48px;
  background-color: ${COLORS.light};
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  position: fixed;
  user-select: none;
  border: 1px solid lightgray;
  top: 0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
  z-index: 100;
  border-radius: 0 0 24px 24px;
`;

const LeftWrapper = styled.div`
  flex: 1;
  display: flex;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all ease-in-out 0.2s;

  &:hover {
    color: ${COLORS.links};
    scale: 1.04;
  }
`;

const LogoLink = styled(Link)`
  color: ${COLORS.black};
  font-weight: 700;
  font-size: 22px;
  text-decoration: none;
  font-family: ${FONT};

  &:hover {
    color: ${COLORS.blue};
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const PictureWrapper = styled.img`
  height: 34px;

  &:hover {
    color: ${COLORS.links};
  }
`;

const EndNavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const LoginLink = styled(Link)`
  color: ${COLORS.black};
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  font-family: ${FONT};
  transition: all ease-in-out 0.2s;

  &:hover {
    scale: 1.04;
    color: ${COLORS.blue};
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const RegisterLink = styled(Link)`
  display: flex;
  background-color: ${COLORS.blue};
  color: ${COLORS.white};
  font-weight: 700;
  font-size: 20px;
  text-decoration: none;
  font-family: ${FONT};
  transition: all ease-in-out 0.2s;
  padding: 4px 12px;
  border-radius: 10px;

  &:hover {
    background-color: ${COLORS.blueGreen};
    scale: 1.06;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export default WithHeader;

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tooltip, Button } from "@mui/material";
import { usePopover } from "../../hooks/use-popover";
import { LanguagePopOver } from "./LanguagePopOver";

import LanguageIcon from "@mui/icons-material/Language";
import { COLORS } from "../../UI/Colors";

export const LanguageSwitch: FC = () => {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <Tooltip title="Language">
        <Button onClick={popover.handleOpen} ref={popover.anchorRef}>
          <Box
            sx={{
              mx: 1,
              pt: 1,
              "& img": {
                width: "100%"
              }
            }}
          >
            <LanguageIcon style={{ color: COLORS.blue }} />
          </Box>
        </Button>
      </Tooltip>
      <LanguagePopOver
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};

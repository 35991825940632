import { useWebClient } from "./useWebClient";
import { useTypedAsyncFn } from "./useTypedAsyncFn";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { MessageSharp } from "@mui/icons-material";

export const usePostTicket = () => {
  const webClient = useWebClient();
  const { t } = useTranslation();

  return useTypedAsyncFn<{
    userID: string;
    title: string;
    contactEmail: string;
    category: string;
    description: string;
    status: string;
    messages: object[];
  }>(async (payload) => {
    const promise = webClient.post(`/ticket`, payload);
    toast.promise(promise, {
      success: t("report.success"),
      error: t("report.error"),
      loading: t("report.loading")
    });
    await promise;
  }, []);
};

import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { getUser } from "../../reducers/auth.reducers";
import { useTranslation } from "react-i18next";
import { COLORS, FONT } from "../../UI/Colors";
import styled from "styled-components";

const DrawerComponent: FC = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const user = useSelector(getUser);
  const isLoggedIn = Boolean(user);
  const { t } = useTranslation();

  const loggedOutRoutes = [
    { name: t("header.home"), route: "/" },
    { name: t("general.register"), route: "/register" },
    { name: t("general.logIn"), route: "/login" }
  ];

  const loggedInRoutes = [
    { name: t("header.home"), route: "/" },
    { name: t("header.discover"), route: "/map" },
    { name: t("header.trending"), route: "/trending" }
  ];

  const routesToDisplay = isLoggedIn ? loggedInRoutes : loggedOutRoutes;

  return (
    <div>
      <Drawer
        sx={{ width: 300 }}
        color="red"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={"right"}
      >
        <List style={{ marginTop: "10px" }}>
          {routesToDisplay.map((route, index: number) => (
            <ListItem key={index} onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <LinkStyle to={route.route}>{route.name}</LinkStyle>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon style={{ color: "#101223" }} />
      </IconButton>
    </div>
  );
};

const LinkStyle = styled(Link)`
  text-decoration: none;
  color: ${COLORS.text};
  font-size: 32px;
  text-decoration: none;
  font-family: ${FONT};

  &:hover {
    color: ${COLORS.blue};
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export default DrawerComponent;

import { useWebClient } from "./useWebClient";
import { useTypedAsyncFn } from "./useTypedAsyncFn";
import { AxiosResponse } from "axios";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export const useFetchCityInfo = () => {
  const webClient = useWebClient();
  const { t } = useTranslation();

  return useTypedAsyncFn<{
    cityName: string;
    lat: number;
    lon: number;
  }>(async (payload) => {
    try {
      console.log(payload);
      const rep: AxiosResponse = await webClient.post(`/trips/infos`, payload);
      // console.log(rep.data);
      return rep.data;
    } catch (error) {
      toast.error(t("map.cityDetailsError"));
      return null;
    }
  }, []);
};
